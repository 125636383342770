_.defer ->

    wsrouter = WSFactory(constants, window.WebSocket)

    # Temporary
    setupWebsocketErrorProcessor(wsrouter)

    # TEMPORARY TODO remove
    ZyncClient = ZyncFactory(wsrouter)
    unless window.Zync?
        window.Zync = {}
    window.Zync.Client = ZyncClient
    Zync = ZyncClient

    if constants.siteType == 'resource'
        PS.resourceSiteApp(
            getPageLoad: wsrouter.getPageLoad
            constantsRaw: constants
        )()
    else if constants.siteType == 'controller'
        PS.controllerApp(
            zync: Zync
        )()
    else
        PS.puxApp(
            constantsRaw: constants
            zync: Zync
            addRoute: (route) -> (callback) -> ->
                route = wsrouter.addRoute(route, (msg) -> callback(msg)())
                (msg) -> -> route.send(msg)
        )()
