// Enable javascript strict mode
"use strict";

// Code that needs to run before pux is bootstrapped
// but doesn't need to be inserted into the page
(function () {
  // Check for out of date browser and redirect if necessary
  // The following eliminates old android browsers and internet explorer
  var hasLocalStorage = typeof localStorage === "object" && typeof localStorage.getItem === "function";
  var hasUnicodeNormalize = typeof String.prototype.normalize === "function";
  var hasBlob = true; try { new Blob(); } catch (e) { hasBlob = false; }
  var isModern = hasLocalStorage && hasUnicodeNormalize && hasBlob

  if (!isModern) {
      // SetTimeout and testing only Blob mitigates problems with bots fetching the wrong page
      console.warn('redirected a user of obsolete browser: '+(navigator || {}).userAgent)
      setTimeout(function() {
          window.location.href = "https://static.schoolshape.com/html/updatebrowser.html";
      }, 5000);
  }

  // Retrieve constants
  var dataEl = document.getElementById('app-data')
  console.log('getting constants')
  if (dataEl) {
      console.log('got constants')
      var appData = JSON.parse(dataEl.innerHTML)
      // window.userData is set up by cross-origin script
      appData.userData = window.userData
      window.constants = appData;
  }
  else {
      // When running unit tests
      window.constants = {};
  }
})();
