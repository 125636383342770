# The following constants are defined in FF,
# but not in Chrome or IE10
if (typeof KeyEvent == "undefined")
    KeyEvent =
        DOM_VK_BACK_SPACE  : 8
        DOM_VK_TAB         : 9
        DOM_VK_RETURN      : 13
        DOM_VK_ENTER       : 14
        DOM_VK_SHIFT       : 16
        DOM_VK_CONTROL     : 17
        DOM_VK_ESCAPE      : 27
        DOM_VK_SPACE       : 32
        DOM_VK_PAGE_UP     : 33
        DOM_VK_PAGE_DOWN   : 34
        DOM_VK_END         : 35
        DOM_VK_HOME        : 36
        DOM_VK_LEFT        : 37
        DOM_VK_UP          : 38
        DOM_VK_RIGHT       : 39
        DOM_VK_DOWN        : 40
        DOM_VK_PRINTSCREEN : 44
        DOM_VK_INSERT      : 45
        DOM_VK_DELETE      : 46
        DOM_VK_ADD         : 107
        DOM_VK_SUBTRACT    : 109

