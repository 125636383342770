var ZyncTextCorrectionsPlugin, ZyncTextCorrectionsFns;
ZyncTextCorrectionsPlugin = function(){
  return {
    addCorrection: function(start, end, id, text, annosAt){
      annosAt == null && (annosAt = _.times(text.length + 1, function(){
        return [];
      }));
      this.addAnno(id, start, end);
      this.so.at('corrections').at(id).update({
        text: text,
        annosAt: annosAt,
        events: []
      });
      return this;
    },
    removeCorrection: function(corrId){
      this.rmAnno(corrId);
      this.so.at('corrections').at(corrId).nullify();
      return this;
    }
  };
}();
ZyncTextCorrectionsFns = function(){
  var MINIMUM_SENTENCE_LENGTH, ref$, filter, Obj, map, orList, fold, elemIndex, reject, isCorrection, combineNLPData, combineCorrectionData, traversal, correctedTraversal, this$ = this;
  MINIMUM_SENTENCE_LENGTH = 3;
  ref$ = require('prelude-ls'), filter = ref$.filter, Obj = ref$.Obj, map = ref$.map, orList = ref$.orList, fold = ref$.fold, elemIndex = ref$.elemIndex, reject = ref$.reject;
  isCorrection = function(it){
    return it.substring(0, 10) === 'correction';
  };
  combineNLPData = function(nlp1, nlp2){
    if (typeof nlp1 === 'object') {
      return nlp1;
    } else if (typeof nlp2 === 'object') {
      return nlp2;
    } else if (nlp1 === 'processing' || nlp2 === 'processing' || nlp1 == null || nlp2 == null) {
      return 'processing';
    } else if (nlp1 === 'ok' || nlp2 === 'ok') {
      return 'ok';
    } else {
      return 'checked';
    }
  };
  combineCorrectionData = function(c1, c2){
    if (c1 === 'incorrect' || c2 === 'incorrect') {
      return 'incorrect';
    } else if (c1 === 'correcting' || c2 === 'correcting') {
      return 'correcting';
    } else if (c1 === 'unknown' || c2 === 'unknown') {
      return 'unknown';
    } else {
      return 'correct';
    }
  };
  traversal = function(text, annosAt, accFn, endSentence){
    var sentenceBoundary, i$, len$, i, annos, j$, len1$, anno, results$ = [];
    endSentence == null && (endSentence = function(){});
    sentenceBoundary = function(annos){
      return elemIndex('s', annos) != null;
    };
    for (i$ = 0, len$ = annosAt.length; i$ < len$; ++i$) {
      i = i$;
      annos = annosAt[i$];
      for (j$ = 0, len1$ = annos.length; j$ < len1$; ++j$) {
        anno = annos[j$];
        if (anno === "s") {
          endSentence(i);
        } else {
          accFn([anno], "", i);
        }
      }
      results$.push(accFn([], text.charAt(i), i));
    }
    return results$;
  };
  correctedTraversal = function(params){
    var text, annosAt, corrections, accFn, doChar, startCorr, endCorr, doSentence, inCorr, isSentence, i$, len$, i, annos, j$, len1$, o, anno, corrImage, k$, ref$, len2$, corrI, innerAnnos, l$, len3$, corrO, innerAnno, results$ = [];
    text = params.text, annosAt = params.annosAt, corrections = params.corrections, accFn = params.accFn, doChar = params.doChar, startCorr = params.startCorr, endCorr = params.endCorr, doSentence = params.doSentence;
    inCorr = false;
    isSentence = function(anno){
      return anno === 's';
    };
    for (i$ = 0, len$ = annosAt.length; i$ < len$; ++i$) {
      i = i$;
      annos = annosAt[i$];
      for (j$ = 0, len1$ = annos.length; j$ < len1$; ++j$) {
        o = j$;
        anno = annos[j$];
        if (isSentence(anno) && !inCorr) {
          doSentence(i, o);
        }
        if (isCorrection(anno) && corrections[anno] != null) {
          inCorr = !inCorr;
          if (inCorr) {
            startCorr(anno, i, o);
            corrImage = corrections[anno];
            for (k$ = 0, len2$ = (ref$ = corrImage.annosAt).length; k$ < len2$; ++k$) {
              corrI = k$;
              innerAnnos = ref$[k$];
              for (l$ = 0, len3$ = innerAnnos.length; l$ < len3$; ++l$) {
                corrO = l$;
                innerAnno = innerAnnos[l$];
                if (isSentence(innerAnno)) {
                  doSentence(i, o, corrI, corrO);
                }
              }
              doChar(corrImage.text.charAt(corrI), i, o, corrI);
            }
          } else {
            endCorr(anno, i, o);
          }
        }
      }
      if (!inCorr) {
        results$.push(doChar(text.charAt(i), i));
      }
    }
    return results$;
  };
  return {
    answerState: function(corrId, text){
      var corrObj;
      text == null && (text = this.text(this.rangeOf(corrId)));
      corrObj = this.getImage().corrections[corrId];
      if (corrObj.hint === 'ok') {
        return 'correct';
      } else if (typeof corrObj.hint === 'object') {
        return 'incorrect';
      } else {
        return 'unknown';
      }
    },
    getSentence: function(hash){
      return this.getImage().nlp[hash];
    },
    getCorrection: function(id){
      return this.getImage().corrections[id];
    },
    getSentenceNLPData: function(hash){
      return this.getImage().nlp[hash];
    },
    correctionSentences: function(){
      var ref$, text, annosAt, corrections, corrs, corrAcc, textAcc, doSentence, doChar, startCorr, endCorr;
      ref$ = this.getImage(), text = ref$.text, annosAt = ref$.annosAt, corrections = ref$.corrections;
      corrs = {};
      corrAcc = [];
      textAcc = '';
      doSentence = function(index, offset, corrIndex, corrOffset){
        var i$, ref$, len$, corrId;
        if (textAcc.trim().length >= MINIMUM_SENTENCE_LENGTH) {
          for (i$ = 0, len$ = (ref$ = corrAcc).length; i$ < len$; ++i$) {
            corrId = ref$[i$];
            corrs[corrId] == null && (corrs[corrId] = []);
            corrs[corrId].push(textAcc);
          }
        }
        textAcc = '';
        return corrAcc = [];
      };
      doChar = function(ch, i){
        return textAcc = textAcc + ch;
      };
      startCorr = endCorr = function(anno, i, o){
        if (!_.contains(corrAcc, anno)) {
          return corrAcc.push(anno);
        }
      };
      correctedTraversal({
        text: text,
        annosAt: annosAt,
        corrections: corrections,
        doChar: doChar,
        startCorr: startCorr,
        endCorr: endCorr,
        doSentence: doSentence
      });
      return corrs;
    },
    correctionNLPData: function(){
      var ref$, text, annosAt, corrections, getCorrectionNLP;
      ref$ = this.getImage(), text = ref$.text, annosAt = ref$.annosAt, corrections = ref$.corrections;
      getCorrectionNLP = compose$(map(compose$(ZyncUtils.hexHashCode, bind$(this, 'getSentence'))), fold(combineNLPData, 'checked'));
      return Obj.map(getCorrectionNLP, this.correctionSentences());
    },
    rangeNLPData: function(start, end, startOffset, endOffset){
      var ref$, text, annosAt, corrections, nlp, sentences, correctionIds, correctedNLP, textAcc, corrAcc, state, evolveState, doSentence, doChar, startCorr, endCorr;
      ref$ = this.getImage(), text = ref$.text, annosAt = ref$.annosAt, corrections = ref$.corrections, nlp = ref$.nlp;
      start == null && (start = 0);
      end == null && (end = text.length);
      startOffset == null && (startOffset = 0);
      endOffset == null && (endOffset = 0);
      if (!(end >= start)) {
        throw new Error('End < start');
      }
      sentences = [];
      correctionIds = [];
      correctedNLP = 'checked';
      textAcc = '';
      corrAcc = [];
      state = 'pre';
      evolveState = function(i, o){
        if (state === 'pre' && (i === start && o >= startOffset || i > start)) {
          state = 'active';
        }
        if (state === 'active' && (i >= end && o >= endOffset || i > end)) {
          return state = 'terminating';
        }
      };
      doSentence = function(index, offset, corrIndex, corrOffset){
        var sentenceNLP;
        evolveState(index, offset);
        if (state === 'active' || state === 'terminating') {
          sentenceNLP = nlp[ZyncUtils.hexHashCode(textAcc)];
          sentences.push(textAcc);
          correctionIds.push.apply(correctionIds, corrAcc);
          correctedNLP = combineNLPData(correctedNLP, sentenceNLP);
        }
        if (state === 'terminating') {
          state = 'post';
        }
        textAcc = '';
        return corrAcc = [];
      };
      doChar = function(ch, i){
        evolveState(i, 0);
        if (state !== 'post') {
          return textAcc = textAcc + ch;
        }
      };
      startCorr = endCorr = function(anno, i, o){
        evolveState(i, o);
        if (state !== 'post' && !_.contains(corrAcc, anno)) {
          return corrAcc.push(anno);
        }
      };
      correctedTraversal({
        text: text,
        annosAt: annosAt,
        corrections: corrections,
        doChar: doChar,
        startCorr: startCorr,
        endCorr: endCorr,
        doSentence: doSentence
      });
      return {
        sentences: sentences,
        correctedNLP: correctedNLP,
        correctionIds: correctionIds
      };
    },
    rangeCorrectionData: function(start, end, corrNLPData){
      var this$ = this;
      corrNLPData == null && (corrNLPData = {});
      return fold(combineCorrectionData, 'correct')(
      map(function(c){
        if (corrNLPData[c] === 'ok' || corrNLPData[c] === 'checked') {
          return this$.answerState(c);
        } else {
          return 'correcting';
        }
      })(
      filter(isCorrection)(
      this.annosAt(start, Math.max(start, end - 1)))));
    },
    sentenceNLPData: function(){
      var ref$, text, annosAt, nlp, res, textAcc, sentenceEnd, doSentence, doChar;
      ref$ = this.getImage(), text = ref$.text, annosAt = ref$.annosAt, nlp = ref$.nlp;
      res = {};
      textAcc = "";
      sentenceEnd = 0;
      doSentence = function(){
        sentenceEnd += textAcc.length;
        res[sentenceEnd] = combineNLPData('checked', nlp[ZyncUtils.hexHashCode(textAcc)]);
        return textAcc = "";
      };
      doChar = function(annos, ch){
        return textAcc = textAcc + ch;
      };
      traversal(text, annosAt, doChar, doSentence);
      return res;
    }
  };
}();
function compose$() {
  var functions = arguments;
  return function() {
    var i, result;
    result = functions[0].apply(this, arguments);
    for (i = 1; i < functions.length; ++i) {
      result = functions[i](result);
    }
    return result;
  };
}
function bind$(obj, key, target){
  return function(){ return (target || obj)[key].apply(obj, arguments) };
}
;
