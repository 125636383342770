# Multiplex multiple independent communications over a single websocket
# A routing key of one character is prepended to all outgoing messages
# to allow them to be routed on the server, and incoming messages are routed
# by their routing key, which is removed from the message
window.WSFactory = (constants, $websocket) ->

    pageLoadIDQ = []
    pageLoadID = constants.pageLoad

    # Attempt to reconnect when disconnected
    websocketReconnectionDelay = 3 * 1000

    ws = undefined # Single websocket

    routes   = {}

    # Sometimes messages arrive before the application has
    # bootstrapped and set up routes, particularly for location and org domains
    # we store them here until addRoute is called
    waitingForRoute = {}

    # Timeout when heartbeats are not received in milliseconds.
    # Should be greater than echo timeout in Websockets.scala
    # to avoid overlapping connections
    hbTimeoutId = null
    hbTimeoutDelay = 20 * 1000
    nSuccessfulConnections = 0

    resetHeartbeatTimeout = ->
        if hbTimeoutId then window.clearTimeout(hbTimeoutId)
        hbCallback = ->
            console.log('no heartbeat heard for ' + hbTimeoutDelay + 's, closing websocket')
            ws.close()
        hbTimeoutId = window.setTimeout(hbCallback, hbTimeoutDelay)

    # Queue up messages for the server until acknowledgement received
    messageQ = []

    # Functions to be notified on next connection success
    onConnectQ = []

    # Record how many messages we received from the server
    nMessagesReceived = 0
    nMessagesToIgnore = 0

    # Record how many messages we sent to the server and were acknowledged
    rmn = "reset-message-number"
    nAckedMessages = 0

    # Flag to stop endless reconnect attempts after server pageload actor has shut down
    # This is true if websocket is not defined in the first place, and otherwise when the pageload actor has shut down
    # In the first case, logging events will be reported by sequel.js, and in the second we aren't interested in them anyhow
    failedPermanently = if _.isObject $websocket then false else true # Fail straight away if no websocket

    connect = ->
        if !pageLoadID?
            console.warn('Pageload ID not given on websocket connect')
            return

        if failedPermanently
            console.log('Websocket failed permanently')
            return

        console.log('Websocket connecting...')
        backend = do ->
            mainUrl = window.location.href
            regex   = /[?&]backend(=([^&#]*)|&|#|$)/
            regex.exec(mainUrl)?[2]

        # Unique url for pageload, indexed by connection attempt
        # to ensure sync between client and server
        url     = constants.urls.api.replace(/^http/, 'ws') +
              '/api/page/' + pageLoadID + '/ws/' + nSuccessfulConnections

        if backend?
            url = url + '?backend=' + backend

        # For quick & dirty monkey-wrench testing
        window.cws = -> ws.close()

        ws = _.extend new $websocket(url),

            onopen: (openEvent) ->
                # Once nSuccessfulConnections > 0, is a reconnection attempt
                nSuccessfulConnections += 1
                if messageQ.length == 0
                    console.log("Websocket connected")
                else
                    # Reconnection.  Send all the unacknowledged messages,
                    # telling the server how many we already received acks for
                    # to avoid duplication
                    if nAckedMessages > 0
                        ws.send("#{rmn}|#{nAckedMessages}")
                    for msg in messageQ
                        ws.send(msg)
                    # Must come after reset-message-number, because it causes
                    # a message to be sent!!!
                    console.log("Websocket connected with #{messageQ.length} messages awaiting dispatch")

                # Reset the heartbeat timeout to avoid it timing out before the
                # first heartbeat is received from the server!
                resetHeartbeatTimeout()

                for fn in onConnectQ
                    fn()
                onConnectQ = []


            onclose: (closeEvent) ->
                console.log("Websocket closed, reconnecting in #{websocketReconnectionDelay}ms")
                _.delay(connect, websocketReconnectionDelay)

            onerror: (errorEvent) ->
                # Log error, if not connected, it will be pushed to the messageQ
                console.log("Websocket connection error occurred, will now close")

            onmessage: (frame) ->
                data    = frame.data

                # Confirmed previous message received ok
                if data == 'ack'
                    if messageQ.length == 0
                        console.error 'Received ack when message Q empty'
                    else
                        messageQ.shift()
                        nAckedMessages += 1
                    return

                # Received directly after reconnect to allow us to
                # de-duplicate received messages
                if data.substring(0, rmn.length) == rmn
                    n = parseInt(data.substring(rmn.length + 1))
                    nMessagesToIgnore = nMessagesReceived - n
                    if nMessagesToIgnore < 0
                        console.error('Message number set greater than number received')
                    else if nMessagesToIgnore > 0
                        console.log("Ignoring next #{nMessagesToIgnore} messages")
                    return

                # Ignore messages that we already received, but the server
                # didn't get our ack for
                if nMessagesToIgnore > 0
                    nMessagesToIgnore -= 1
                    return

                if data == 'hb'
                    # Close the websocket and trigger reconnect if
                    # heartbeats are not continuously received
                    resetHeartbeatTimeout()
                    # Reply to heartbeats with an echo
                    ws.send('echo')
                    PS?.wsMessages.set('hb') # Pass to PS
                    return

                npl = 'no_page_load'
                if data.substring(0, npl.length) == npl
                    # Permanent failure because the server PageLoad actor
                    # has timed out, or the server was reset or upgraded
                    vs = data.substring(npl.length + 1)
                    if vs == constants.vs
                        PS?.wsMessages.set(npl)
                    else
                        PS?.wsMessages.set('version_change')
                    failedPermanently = true
                    return

                if data == 'reject_duplicate_connection'
                    # If page was resucitated, eg: using Chrome 'duplicate tab'
                    # then we need to reload properly to avoid duplicate page id in play
                    console.log('ooooops')
                    #location.reload()

                # We know now the message is a 'real' message, not a 'meta' message
                nMessagesReceived += 1
                ws.send('ack')

                splitAt = data.indexOf '|'
                if splitAt < 0 then throw new Error ('Illegal frame: ' + data)
                routingKey = data.substring(0, splitAt)
                message    = data.substring(splitAt + 1)
                callback   = routes[routingKey]

                if callback?
                    callback(message)
                else
                    # Sometimes, updates to zync objects can occur before startup is complete
                    # We store them waiting for addRoute to be called ...
                    unless waitingForRoute[routingKey]
                        waitingForRoute[routingKey] = []
                    waitingForRoute[routingKey].push(message)
                    if window.performance && window.performance.now() > 10000.0
                        # .. but this shouldn't happen after the app has started up
                        console.warn("Unroutable message received at client after startup: #{data}")

    if constants.pageLoad?
        connect() # Connect straight away
    else
        # Start pageload and websocket or fail silently on resource / static sites
        # NB this code has been copy-pasted to drupal site to get pageload
        # logging there
        xhr = new XMLHttpRequest()
        payload =
            window.location.href + '\n' +
            performance.now().toString()
        if document.referrer.length > 0
            payload += '\n' + document.referrer
        xhr.withCredentials = true
        xhr.open('POST', constants.urls.api + '/api/page', true)
        xhr.onreadystatechange = ->
            if xhr.readyState == XMLHttpRequest.DONE and xhr.status == 200
                pageLoadID = xhr.responseText.toString()
                pageLoadIDQ.map (fn) -> fn(pageLoadID)()
                pageLoadIDQ = []
                connect()
        xhr.send(payload)

    doSend = (key, msg) ->
        frame = key + '|' + msg
        messageQ.push(frame)
        if ws? and $websocket? and ws.readyState == $websocket.OPEN
            ws.send(frame)

    # Return API
    {
        # Route incoming messages from the websocket
        # Assumes incoming messages are of the form
        # routingKey|message
        addRoute: (routingKey, callback) ->
            if routes[routingKey]?
                throw new Error "Attempt to claim already existing route #{routingKey}"
            routes[routingKey] = callback

            # Clear any early arriving messages
            if waitingForRoute[routingKey]
                for msg in waitingForRoute[routingKey]
                    callback(msg)
                delete waitingForRoute[routingKey]

            return {

                isOpen: -> ws? and $websocket? and ws.readyState == $websocket.OPEN

                # Send a message to the websocket with this routing key
                # Keeps trying to send message with no timeout
                send: (msg) -> doSend(routingKey, msg)

                # Remove this route
                # Call this method to avoid a memory leak
                close: ->
                    routes[routingKey] = undefined
            }

        # Added specially to allow subscribing to zync queries from purescript
        sendRaw: (key, msg) -> doSend(key, msg)

        onConnect: (fn) ->
            if ws? and $websocket? and ws.readyState == $websocket.OPEN
                fn()
            else
                onConnectQ.push(fn)

        getPageLoad: (fn) -> ->
            if pageLoadID?
                fn(pageLoadID)()
            else
                pageLoadIDQ.push(fn)
    }

