
# ************** Zync *****************
#
# Represents an object which is synchronized across clients via a server
# objects can be mutated while offline, then synced later
#
# Syntax:
#
# Create a new object, with a new uuid:
# so = Zync.create('domain here')
#
# .. or fetch an existing object from the server
# so = Zync.fetch(uuid)
#
# A plain immutable javascript object representation is available at
# so.image()
#
# Set values on the object:
# so.at('prop1').update('value1')
#
# Operations on arrays and strings can be called as follows:
# so.at('array_prop').insert index, ['new', 'items', 'for', 'array']
# so.at('string_prp').insert index, 'string to insert'
# so.at('prop').delete index, nToDelete
# so.at('prop').splice index, nToDelete, ['new', 'items']
#
# Operations can be chained, and should be committed when complete
# so.at('prop').delete(0, 1)
#              .delete(4, 1)
#              .insert(3, 'Hello')
#              .commit()
#
# To be notified of changes (whether local or from the server)
#
# so.onChange (newImage, updateOperationsArray) ->
#   .. process the new zync object here
#
# To 'unlisten', call
#
# so.unListen(method)
#
# To return a sub-shared object, use 'at'
# subobject  = so.at('a.b.c.d')
# subobject2 = so.at('a', 'b', 'c').at('d')
#
#
unless Zync? then Zync = {}
Zync.Schema =
    instantiate: (type) ->
        recurse = Zync.Schema.instantiate
        type.default ? switch type.name
            when 'any' then undefined
            when 'optional' then undefined
            when 'number' then 0
            when 'string' then ''
            when 'boolean' then false
            when 'dict' then {}
            when 'list'
                if type.size? and type.size > 0
                    _.times(type.size, -> recurse(type.subtype))
                else
                    []
            when 'object'
                result = {}
                for key, subtype of type.fields
                    result[key] = recurse(subtype)
                result
            else
                throw new Error "Type #{type.name} has no default value"

    subtype: (schema, prop)  ->
        if schema.name == 'any'
            return name: 'any'
        else if schema.name == 'list' and !prop?
            return schema.subtype
        else if schema.name == 'dict'
            return schema.subtype
        else if schema.name == 'object' and prop of schema.fields
            return schema.fields[prop]
        else if schema.name == 'union'
            return name: 'any'
        else
            throw new Error "Could not find subtype of schema #{JSON.stringify schema}, property #{prop}"

ZyncFactory = (wsrouter) ->

    log = console
    subtype = Zync.Schema.subtype # TODO: remove global reference

    # User Id for tagging commits
    userId = undefined

    deepFreeze = (obj) ->
        if !_.isObject(obj) then return obj # Avoid safari errors
        for key, val of obj
            if obj.hasOwnProperty(key) and _.isObject(val)
                deepFreeze(val)
        Object.freeze(obj)

    generateUuid = ->
        # Adapted from http://stackoverflow.com/a/2117523/469981
        'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace /[xy]/g, (c) ->
            r = Math.random()*16|0
            (if c == 'x' then r else (r&0x3|0x8)).toString(16)

    clone = (obj) ->
        if !obj?
            throw new Error 'Cannot clone undefined'
        else
            JSON.parse(JSON.stringify obj)


    # Updates the given image
    updateImage = (commits, target, schema) ->
        for commit in commits
            op = commit.op
            opType = if !op? then undefined else 'JsonOp'
            target = apply(target, opType, op, commit.created, commit.author, schema)
        target

    # Send updates to listeners, passed vs is image vs, not op vs
    updateCommitListeners = (listeners, commits, target, schema, vs) ->
        ops = _.pluck(commits, 'op')
        op = if ops.length > 0 and ops.length < 50 # > 20 ops may cause performance issues
                 # Make an op which represents the entire update
                 composedOp = _.foldl(ops, (a, b) -> composeJsonOps(b, a, schema))
                 opType = if composedOp == null then 'Id' else 'JsonOp'
                 new Op(opType, composedOp)
             else
                 # We re-initialized the object, or applied a large number of ops.  Simulate this as an
                 # update for clients that use the op information
                 # by replacing the entire object
                 new Op('Update', target)

        # Notify imperative listeners
        for listener in listeners
            # Prevent reentrance of Zync by listeners
            ( (listener) -> _.defer ->
                try
                    listener.onCommit(target, op, vs)
                catch e
                    log.error 'Error in listener handler: '
                    log.error e.message
                    log.error e.stack
            )(listener)

    # Send image load to listeners
    updateImageListeners = (listeners, image, vs) ->
        for listener in listeners
            ( (listener) -> _.defer ->
                try
                    listener.onImage(image, vs)
                catch e
                    log.error 'Error in listener handler: '
                    log.error e.message
                    log.error e.stack
            )(listener)

    # Send errors to listeners
    updateErrorListeners = (listeners, err) ->
        for listener in listeners
            ( (listener) -> _.defer ->
                try
                    listener.onError(err)
                catch e
                    log.error 'Error in listener handler: '
                    log.error e.message
                    log.error e.stack
            )(listener)

    # Defines the serialization format for operations
    createOp = (opType, op, el, image) ->
        getSuperOp = (opType, op, el) ->
            newObj = {}
            opIndex = if opType == 'JsonOp' then el else opType + '$$' + el
            newObj[opIndex] = op
            newObj
        if _.isNumber(el) and _.isArray(image)
            newArr = []
            if el > 0 then newArr.push el
            newArr.push getSuperOp(opType, op, 'm')
            rem = image.length - el - 1
            if rem > 0 then newArr.push rem
            newArr
        else if _.isString(el) and _.isObject(image)
            getSuperOp(opType, op, el)
        else
            throw new Error "Illegal path element #{el} against image #{image}"

    addOpToObject = (target, key, opType, opValue) ->
        prefix = if opType == 'JsonOp' then '' else opType+'$$'
        target[prefix + key] = opValue
        target


    # Determine whether the given object is an operation
    isOp = (key) ->
        key.indexOf('$$') > 0

    opOf = (key) ->
        z = key.split('$$')
        if z.length == 2
            z
        else
            ['JsonOp', key]

    # Convenience functions for info about slice operations
    isChange = (op) -> (_.isArray(op.i) or _.isString(op.i)) and _.isNumber(op.d)
    isKeep   = (op) -> _.isNumber(op)
    isModify = (op) ->
        _.isObject(op.m) or _.find(_.keys(op), (key) => key.indexOf("$$") > 0 )

    parseModify = (op) ->
        key = _.keys(op)[0]
        [opOf(key)[0], op[key]]

    # Length of slice to which this array operation can be applied
    preLen = (op) ->
        if _.isNumber(op) then op
        else if isChange(op) then op.d
        else if isModify(op) then 1
        else
            throw new Error "Unknown array op #{spliceOpToString op}"

    # Length of the slice produced by this array operation
    postLen = (op) ->
        if _.isNumber(op) then op
        else if isChange(op) then op.i.length
        else if isModify(op) then 1
        else
            throw new Error "Unknown array op #{spliceOpToString op}"

    # Split an array operation to apply to two smaller chunks
    opSplit = (op, n) ->
        if n > preLen(op) then throw new Error 'Illegal split'
        if _.isNumber(op)
            [n, op - n]
        else
            if isChange(op)
                [{i: op.i, d: n}, {i: (if _.isString(op.i) then "" else []), d: op.d - n}]
            else if isModify(op)
                throw new Error 'Cannot split modify operation'
            else
                throw new Error 'Unknown op'


    opPostSplit = (op, n) ->
        if n > postLen(op) or n < 1 then throw new Error "Illegal split #{n}"
        if _.isNumber(op)
            [n, op - n]
        else
            if isChange(op)
                [{i: op.i.slice(0, n), d: op.d}, {i: op.i.slice(n), d: 0}]
            else if isModify(op)
                throw new Error 'Cannot split modify'
            else
                throw new Error 'Unknown op'

    spliceOpToString = (spliceOp) ->
        if _.isNumber(spliceOp)
            spliceOp.toString()
        else if _.isObject(spliceOp)
            if spliceOp.d > 0 and spliceOp.i?.length == 0
                "-#{spliceOp.d}"
            else if spliceOp.i?.length > 0 and spliceOp.d == 0
                "+#{JSON.stringify spliceOp.i}"
            else if spliceOp.i? and spliceOp.d?
                "-#{spliceOp.d}/+#{JSON.stringify spliceOp.i}"
            else
                keys = _.keys(spliceOp)
                modifyOp = keys[0]
                [opType, opName] = opOf(keys[0])
                if opName != 'm'
                    throw new Error "Cannot convert #{JSON.stringify spliceOp} to string"
                opToString(opType, spliceOp[keys[0]])
        else
            throw new Error "Cannot convert #{JSON.stringify spliceOp} to string"

    rootOpToString = (op) ->
        opType = if _.isObject(op) then 'JsonOp' else undefined
        opToString(opType, op)

    # Convert an op to a terse string representation, helpful for debugging
    opToString = (opType, op) ->
      if !opType? then 'Id'
      else switch opType
          when 'Splice'
              "<#{_.map(op, spliceOpToString).join(' ')}>"
          when 'JsonOp'
              if !_.isObject(op) then throw new Error "JsonOp #{JSON.stringify op} should be object"
              kvs =
                  for k, subOp of op
                      [subOpType, subOpName] = opOf(k)
                      "#{subOpName}#{opToString(subOpType, subOp)}"
              "{#{kvs.join(", ")}}"
          when 'Update'
              "=#{JSON.stringify op}"
          when 'Replace'
              "==#{JSON.stringify op}"
          when 'Incr'
              "+=#{op}"
          else
              throw new Error "Cannot convert #{JSON.stringify op} to string"


    commitToString = (commit) ->
        "|#{commit.vs}: #{opToString (if commit.op? then 'JsonOp' else undefined), commit.op}|"



    # Apply splice operation to a string or array
    applyArrayOp = (spliceTarget, opList, created, author, schema) ->
        # Create a variable to accumulate the result
        result =
            if _.isString spliceTarget then ''
            else if _.isArray spliceTarget then []
            else throw new Error "Illegal splice operation " +
               "#{JSON.stringify opList} in #{JSON.stringify spliceTarget}"
        # n is the index into the spliceTarget
        n = 0
        for arrOp in opList
            # Take a slice of spliceTarget, apply the
            # operation to it, then accumulate the result
            l        = preLen(arrOp)
            slice    = spliceTarget.slice(n, n+l)
            newSlice =
                if _.isNumber arrOp
                    slice # Keep op
                else if isChange(arrOp)
                    arrOp.i  # Replace or Insert
                else if isModify(arrOp)
                    [opType, opValue]  = parseModify(arrOp)
                    slice.map( (el) => apply(el, opType, opValue, created, author, subtype(schema) ))
            if _.isString result then result += newSlice
            else result.push newSlice...
            n += l
        unless n == spliceTarget.length
            throw new Error "Cannot apply splice #{JSON.stringify opList} n=#{n} to #{JSON.stringify spliceTarget}"
        unless _.isString result then Object.freeze result else result


    apply = (target, opType, opValue, created, author, schema) ->
        if !opType?
            # Identity operation
            return target
        else switch opType
            when 'Update', 'Replace'
                # Simple 'Set' operation
                if _.isObject(opValue)
                    # Quick hack to get author and creation date into
                    # a subset of values
                    # TODO: remove this as unused?
                    value = clone(opValue)
                    value.createdAt = created
                    value.createdBy = author
                    value
                else
                    opValue
            when 'Incr'
                unless _.isNumber(target)
                    throw new Error("Increment on #{target}")
                unless _.isNumber(opValue)
                    throw new Error("Increment with value #{opValue}")
                target + opValue
            when 'Splice'
                # Splice on a string or array
                applyArrayOp(target, opValue, created, author, schema)
            when 'JsonOp'
                applyJsonOp(target, opValue, created, author, schema)
            else
                throw new Error "Unknown opType #{opType}"


    # Apply an operation to an object, returning a new object
    # FUTURE: make this prettier/more efficient with Zippers?
    applyJsonOp = (target, opObject, created, author, schema) ->

        unless schema.name in ['dict', 'object', 'any', 'union']
            throw new Error "Cannot apply Json Op #{JSON.stringify opObject} to schema #{JSON.stringify schema}, target #{JSON.stringify target}"

        unless _.isObject(target) and _.isObject(opObject) and !_.isArray(target) and !_.isArray(opObject)
            throw new Error "Undefined op (#{JSON.stringify opObject}) or target (#{JSON.stringify target})"

        # Partition the opObject into ops that apply at this path, and those that
        # recurse to a deeper object
        [opsHere, opsAtDepth] = _.partition _.keys(opObject), isOp

        # Create a map of key -> [opType, opValue] for this path
        ops = {}
        for key in opsHere
            [opType, opKey] = opOf key
            ops[opKey] = [opType, opObject[key]]

        # Build a new object for the result rather than mutating the original
        result = if _.isArray(target) then [] else {}
        for key, value of target
            result[key] =
                if key of ops
                    # There is an operation which needs to be applied here
                    [opType, opValue] = ops[key]
                    apply(value, opType, opValue, created, author, subtype(schema, key))
                else if key in opsAtDepth
                    # Apply sub-op to sub-object
                    applyJsonOp(target[key], opObject[key], created, author, subtype(schema, key))
                else
                    # Operation doesn't act here
                    # copy the already immutable object across
                    target[key]

        # Apply set ops to properties which don't exist yet
        for key, [opType, opValue] of ops when key not of target
            unless opType in ['Update', 'Replace']
                throw new Error "Illegal operation #{JSON.stringify opType} on #{JSON.stringify target}"
            result[key] = opValue # opValue should be immutable

        # Remove properties that have been set to null
        for key, value of result
            delete result[key] if !value?

        return Object.freeze result

    normalizeJsonOp = (opObject) ->

        # Allow commit(prop: value) instead of commit(prop: Zync.update(value))
        newObject = {}
        for key, value of opObject
            [opType, prop] = opOf(key)
            newValue = switch opType
                when 'Splice'
                    normalize2 = (a, b) ->
                        if isKeep(a) and isKeep(b)
                            [a + b]
                        else if isChange(a) and isChange(b)
                            newI =
                                if _.isString(a.i) and _.isString(b.i)
                                    a.i + b.i
                                else if _.isArray(a.i) and _.isArray(b.i)
                                    a.i.concat b.i
                                else
                                    throw new Error 'Unexpected inserts'
                            [{d: a.d + b.d, i: newI}]
                        else
                            [a, b]

                    newSplice = []
                    for spliceOp in value
                        # First check for recursion
                        if isModify(spliceOp)
                            spliceOp = normalizeJsonOp(spliceOp)
                            if !spliceOp?
                                spliceOp = 1 # Keep(1)

                        # Skip non-ops such as {d: 0, i: []}
                        if postLen(spliceOp) == 0 and preLen(spliceOp) == 0
                            continue

                        # No normalization needed on first op
                        if newSplice.length == 0
                            newSplice.push(spliceOp)

                        # Normalize the ops in pairs
                        else
                            lastSpliceOp = newSplice.pop()
                            newSplice.push(normalize2(lastSpliceOp, spliceOp)...)
                    if newSplice.length == 0 or newSplice.length == 1 and isKeep(newSplice[0])
                        null
                    else
                        newSplice
                when 'JsonOp'
                    # Just recurse
                    normalizeJsonOp(value)
                when 'Incr'
                    # Normalize away increment by 0
                    if value == 0
                        null
                    else
                        value
                else
                    value

            if opType in ['Update', 'Replace'] or newValue?
                # Reject undefined as the identity operation,
                # but keep Update: undefined and Replace: undefined as delete
                newObject[key] = newValue

        # make the operation immutable
        if _.keys(newObject).length == 0
            null # JSON.stringify returns "null" rather than null for undefined
        else
            Object.freeze(newObject)

    transposeSpliceOp = (a, b) ->

        # a and b must have equal prelengths
        unless preLen(a) == preLen(b)
            throw new Error "Illegal op splice transpose #{spliceOpToString a} and #{spliceOpToString b}"

        # Pass through 'Keep' operations
        if isKeep(a)      then [postLen(b), b]
        else if isKeep(b) then [a, postLen(a)]

        # Deal with deletion operations
        #else if isChange(a) and isChange(b)
        else if isChange(a)
            [{i: a.i, d: postLen(b)}, postLen(a)]
        else if isChange(b)
            [postLen(b), {i: b.i, d: postLen(a)}]
        # Recurse for modify slices
        else if isModify(a) and isModify(b)
            [aType, aOp] = parseModify(a)
            [bType, bOp] = parseModify(b)
            [resAType, resA, resBType, resB] = transpose(aType, aOp, bType, bOp)
            newA =
                if resA?
                    addOpToObject({}, 'm', resAType, resA)
                else
                    1
            newB =
                if resB?
                    addOpToObject({}, 'm', resBType, resB)
                else
                    1
            [newA, newB]
        else
            throw new Error "Cannot transpose #{spliceOpToString a} and #{spliceOpToString b}"

    transposeSplice = (a, b) ->
        NO_MORE_ELEMENTS = {}
        # Create arrays to receive the transpose result
        [resAs, resBs] = [[], []]
        # Reverse the input and make it writeable ready for processing
        [aOps, bOps] = [clone(a).reverse(), clone(b).reverse()]

        # Read through the ops until all are transposed
        while (aOps.length > 0 || bOps.length > 0)
            nextA = if aOps.length > 0 then aOps.pop() else NO_MORE_ELEMENTS
            nextB = if bOps.length > 0 then bOps.pop() else NO_MORE_ELEMENTS

            unless nextA? and nextB?
                throw new Error "Encountered null while transposing #{opToString 'Splice', a} and #{opToString 'Splice', b}"

            # Deal with the case of trailing inserts which cannot
            # be paired with anything for transposition
            if nextA == NO_MORE_ELEMENTS
                if preLen(nextB) > 0 then throw new Error "Second operand of splice transpose #{opToString 'Splice', a}, #{opToString 'Splice', b} too long"
                resBs.push(nextB)
                # Certain that postLen(nextB) > 0
                resAs.push(postLen(nextB))
                continue
            if nextB == NO_MORE_ELEMENTS
                if preLen(nextA) > 0
                    throw new Error "First operand of splice transpose #{opToString 'Splice', a}, #{opToString 'Splice', b} too long"
                resAs.push(nextA)
                # Certain that postLen(nextA) > 0
                resBs.push(postLen(nextA))
                continue

            # Split the operations into matching chunks and
            # put the remainder back into the input
            [la, lb] = [preLen(nextA), preLen(nextB)]
            [splitA, splitB] =
                if lb == 0
                    # A has no prelength, deal with it first
                    aOps.push nextA
                    [0, nextB]
                else if la == 0
                    # B has no prelength, deal with it first
                    bOps.push nextB
                    [nextA, 0]
                else if la == lb
                    [nextA, nextB]
                else if la > lb
                    [newA, rem] = opSplit(nextA, lb)
                    aOps.push rem
                    [newA, nextB]
                else if lb > la
                    [newB, rem] = opSplit(nextB, la)
                    bOps.push rem
                    [nextA, newB]

            # Transpose the now matching chunks
            if isChange(splitA) and isChange(splitB)
                if postLen(splitB) > 0
                    resAs.push postLen(splitB)
                if postLen(splitA) > 0
                    resAs.push {i: splitA.i, d: 0}
                if postLen(splitB) > 0
                    resBs.push {i: splitB.i, d: 0}
                if postLen(splitA) > 0
                    resBs.push postLen(splitA)
            else
                [resA, resB] = transposeSpliceOp(splitA, splitB)
                if !resA? or !resB?
                    throw new Error "Produced null result (#{JSON.stringify resA}, #{JSON.stringify resB}) transposing #{JSON.stringify splitA}, #{JSON.stringify splitB}"
                resAs.push resA if resA != 0
                resBs.push resB if resB != 0
        [resAs, resBs]


    # Transpose two operations against each other
    # a is the client operation, b is the authoritative pre-existing operation
    transpose = (aType, a, bType, b) ->
        unless aType? and bType?
            throw new Error 'No type in transpose'
        if bType == 'Replace' or bType == 'Update'
            [undefined, undefined, bType, b]
        else if aType == 'Replace' or aType == 'Update'
            [aType, a, undefined, undefined]
        else if aType == 'Splice' and bType == 'Splice'
            [splA, splB] = transposeSplice(a, b)
            ['Splice', splA, 'Splice', splB]
        else if (aType == 'JsonOp') and (bType == 'JsonOp')
            [jsA, jsB] = transposeJsonOp(a, b)
            ['JsonOp', jsA, 'JsonOp', jsB]
        else if (aType == 'Incr') and (bType == 'Incr')
            ['Incr', a, 'Incr', b]
        else
            throw new Error "Unknown op types #{aType}, #{bType}"

    # Transpose two commands, each of which may contain many operations
    # a: The first argument is the unconfirmed client operation
    # b: The second argument is the confirmed server operation and has
    #    priority where unresolvable conflicts occur
    transposeJsonOp = (a, b) ->
        newA = {}
        newB = {}

        # eg: Update$$prop1: value1
        # Create a dictionary of keys (eg: prop1) to
        #   the original Key (eg: Update$$prop1)
        #   the opType (eg: Update)
        #   the property (eg: value1)
        dictA = {}
        dictB = {}
        for key, value of a
            [opType, opKey] = opOf(key)
            dictA[opKey] = [key, opType, a[key]]
        for key, value of b
            [opType, opKey] = opOf(key)
            dictB[opKey] = [key, opType, b[key]]
        for key in _.union (_.keys dictA), (_.keys dictB)
            [fullKeyA, opTypeA, an] = dictA[key] ? [key, undefined, undefined]
            [fullKeyB, opTypeB, bn] = dictB[key] ? [key, undefined, undefined]
            [newAType, newAn, newBType, newBn] =
                if !opTypeA? or !opTypeB?
                    # One or the other key isn't defined
                    # Just pass through
                    [opTypeA, an, opTypeB, bn]
                else
                    # Both keys present, recurse
                    transpose(opTypeA, an, opTypeB, bn)
            addOpToObject(newA, key, newAType, newAn) if newAType?
            addOpToObject(newB, key, newBType, newBn) if newBType?
        [newA, newB]

    # Alters a path to take account of other operations
    # moving it around
    #
    # eg: for an object image
    #     arr: ['a', 'b']
    # and a path ['arr', 1] which points to 'a',
    # if a value is inserted before 'a', to make the image
    #     arr: ['x', 'a', 'b']
    # then the path should become
    #     ['arr', 2]
    # thus, still pointing to 'a'
    transposePath = (aType, a, path) ->
        # Empty paths cannot be transformed
        # If operations are all deeper than this path, or the operation is identity
        # then the path stays the same
        if !path? or path.length == 0 or a == null then return path
        pathHead = _.head(path)
        pathTail = _.tail(path)
        if pathTail.length == 0
            # If the operation applies exactly to this path, replacing or
            # mutating the object the path points to, we can keep this path,
            # which will then point to the new object
            # This deals with increment operations, and update and replace at the end of the path
            path
        else if aType == 'JsonOp'
            # JSON Operation on a subpath of this path
            # Get the operations that affect this path
            opsHere =
                _.keys(a)
                    .filter (key) => opOf(key)[1] == pathHead

            # This operation doesn't affect this path
            if opsHere.length == 0 then return path

            # More than one operation at the same point
            if opsHere.length > 1 then throw new Error 'Two operations at the same point'

            # Found exactly one operation here
            [opType, key] = opOf(opsHere[0])

            [pathHead].concat transposePath(opType, a[opsHere[0]], pathTail)

        else if aType == 'Update' or aType == 'Replace'
            # This path has been cut off by an
            # update in its midriff
            throw new Error 'Invalidated this path'
        else if aType == 'Splice'
            # Splice around the path's midriff
            # This component of the path must be a number
            unless _.isNumber(pathHead)
                throw new Error "Can't transpose '#{pathHead}' with splice of '#{el}'"
            # Let's calculate the new index
            shift = 0
            keep  = 0
            for spliceOp in a
                dKeep  = preLen(spliceOp)
                dShift = postLen(spliceOp) - dKeep
                # Delete op - shift left, or invalidate
                if keep + dKeep > pathHead
                    if spliceOp.d?
                        throw new Error 'Invalidated this path'
                    else if isModify(spliceOp)
                        # Sub-operation on this slice,
                        # Transform the tail of this path
                        [subOpType, subOp] = parseModify(spliceOp)
                        pathTail = transposePath(subOpType, subOp, pathTail)
                    break
                keep  += dKeep
                shift += dShift
            pathHead += shift
            # Shiny new path
            [pathHead].concat(pathTail)
        else
            throw new Error 'Unknown op type'


    # Compose single splices of identical length as a . b
    # ie: b is applied first
    # eg: {Update$$m: 3}, {i: [5], d: 0}
    composeSplice = (a, b, schema) ->
        unless postLen(b) == preLen(a) and postLen(b) > 0
            throw new Error "Illegal compose of splices #{spliceOpToString a} and #{spliceOpToString b}"
        if isKeep(a)
            # a keep op in a doesn't affect b
            b
        else if isKeep(b)
            # a keep op in b doesn't affect a
            a
        else if isChange(a)
            # a deletes whatever was modified in b
            { d: preLen(b), i: a.i }
        else if isChange(b)
            # An insert in a which is modified in b
            # Must apply a to contents of b
            # TODO make more accurate
            aCreated = Date.now()
            aAuthor = 0
            { d: b.d, i: applyArrayOp(b.i, [a], aCreated, aAuthor, schema) }
        else if isModify(a) and isModify(b)
            # Modify ops, recurse
            [opTypeA, opA] = parseModify(a)
            [opTypeB, opB] = parseModify(b)
            [newOpType, newOp] = compose(opTypeA, opA, opTypeB, opB, subtype schema)
            addOpToObject({}, 'm', newOpType, newOp)
        else
            throw new Error "Cannot compose #{JSON.stringify a} and #{JSON.stringify b}"

    # Composes splice operations of identical length on a whole array a.b
    # ie, b is applied before a
    # eg: [1, {i: 'Hi'}, 2] with [2, {d: 1}, 1]
    composeSplices = (a, b, schema) ->
        # If a and b are Nil, we are finished
        if a.length == 0 and b.length == 0 then return []

        # Check for 0-postLength operations in a, which can be passed through
        a0 = _.head(a)
        if a0? and preLen(a0) == 0
            return [a0].concat composeSplices(_.tail(a), b, schema)

        # Check for 0-preLength operations in b, which can be passed through
        b0 = _.head(b)
        if b0? and postLen(b0) == 0
            return [b0].concat composeSplices(a, _.tail(b), schema)

        if b.length == 0 and a.length > 0 or a.length == 0 and b.length > 0
            throw new Error "Composed slices of unequal length #{JSON.stringify a} and #{JSON.stringify b}"

        la = preLen(a0)
        lb = postLen(b0)
        remA = _.tail(a)
        remB = _.tail(b)
        if la > lb
            # Splice up the first op in a, compose the matching slices and recurse
            [a0, a1] = opSplit(a0, lb)
            [composeSplice(a0, b0, schema)].concat composeSplices([a1].concat(remA), remB, schema)
        else if lb > la
            # Slice up the first op in b, compose the matching slices and recurse
            [b0, b1] = opPostSplit(b0, la)
            [composeSplice(a0, b0, schema)].concat composeSplices(remA, [b1].concat(remB), schema)
        else # la == lb
            # No slicing needed, just compose the matching slices and recurse
            [composeSplice(a0, b0, schema)].concat composeSplices(remA, remB, schema)

    # Compose two operation a and b as a.b, ie: b is applied first
    compose = (aType, a, bType, b, schema) ->
        if aType == 'Update' or aType == 'Replace'
            # The update on the second op wins
            [aType, a]
        else if a == undefined
            # a is identity operation
            [bType, b]
        else if b == undefined
            # b is identity operation
            [aType, a]
        else if bType == 'Update' or bType == 'Replace'
            # b updates the object and a operated on the updated value
            # TODO: make more accurate in future:
            aCreated = Date.now()
            aAuthor = 0
            [bType, apply(b, aType, a, aCreated, aAuthor, schema)]
        else if aType == 'JsonOp' and bType == 'JsonOp'
            ['JsonOp', composeJsonOps(a, b, schema)]
        else if aType == 'Splice' and bType == 'Splice'
            ['Splice', composeSplices(a, b, schema)]
        else if aType == 'Incr' and bType == 'Incr'
            ['Incr', a + b]
        else
            throw new Error "Illegal composition of #{aType} and #{bType}"

    composeJsonOps = (a, b, schema) ->
        # Make a record of all ops on a and b
        result = {}
        dictA = {}
        dictB = {}
        for key, value of a
            [opType, opKey] = opOf(key)
            dictA[opKey] = [opType, a[key]]
        for key, value of b
            [opType, opKey] = opOf(key)
            dictB[opKey] = [opType, b[key]]
        keys = _.union (_.keys dictA), (_.keys dictB)
        result = {}
        for key in keys
            [opTypeA, a_] = dictA[key] ? [key, undefined, undefined]
            [opTypeB, b_] = dictB[key] ? [key, undefined, undefined]
            if a_? and b_?
                # Recurse as both properties are operated on
                [newOpType, newOpValue] = compose(opTypeA, a_, opTypeB, b_, subtype(schema, key))
                addOpToObject(result, key, newOpType, newOpValue)
            else if a_?
                # Only a is affected, pass through
                addOpToObject(result, key, opTypeA, a_)
            else if b_?
                # Only b is affected, pass through
                addOpToObject(result, key, opTypeB, b_)
        result

    # *** State ***
    # Define some global zync object state which
    # is necessary for communicating with the server and hiding
    # mutable state for individual zync object
    # This is a map of UUID to state
    state = {}

    # Set up server communications
    routes = {}

    # Send server events to an FRP framework
    frpImageChannel = ->
    frpPeerChannel  = ->

    # Class representing the state of a zync object
    ZyncState = (@domain, @uuid, isCreate, @isLocal, clientImageOrVs) ->

        # Either a client image for initialization
        # or a max version for fetching can be passed
        clientImage = undefined
        maxVs       = undefined
        if _.isNumber(clientImageOrVs)
            maxVs = clientImageOrVs
        else
            clientImage = clientImageOrVs

        # Initialize or completely reset object state
        initializeFromImage = (historyStart, image) =>
            @unappliedOps  = [] # Array of operations yet to be appended
            @localHistory  = [] # Mutable array
            @historyStart  = historyStart  # History may not extend back to object creation
            @sentToServer  = 0  # Number of commits from localHistory
            @serverHistory = []
            unless schemata[@domain]?
                throw new Error "Domain #{@domain} not found when initializing from image"
            @schema = schemata[@domain]
            @startImage = @serverImage = @localImage = image

        # Initialize a brand new object
        initializeFromSchema = =>
            initializeFromImage(0, Zync.Schema.instantiate(schemata[@domain]))

        # Set data to 'uninitialized' state
        @unappliedOps  = [] # Array of operations yet to be appended
        @localHistory  = [] # Mutable array
        @historyStart  = 0  # History may not extend back to object creation
        @sentToServer  = 0  # Number of commits from localHistory
        @serverHistory = []
        @dormant = false # We always immediately subscribe / request state

        @listeners       = [] # Mutable array

        # We know the server image will be initialized from the schema
        # Safari throws an error for deepFreeze(undefined), so first check that we have an object
        if isCreate
            unless schemata[@domain]?
                throw new Error "Domain #{@domain} not found in  schemata"
            if clientImage?
                # TODO refactor initialization routines
                initializeFromImage(clientImage.historyStart, clientImage.image)
                commits = (obj for obj in clientImage.history).reverse()
                @receiveFromServer(commits...)
            else
                initializeFromSchema()
        else
            # Initialize images on load
            @schema      = undefined
            @serverImage = undefined
            @startImage  = undefined
            @localImage  = undefined

        # Local objects do not communicate with the server
        if @isLocal then return this

        @commitRoute = wsrouter.addRoute "#{@domain}/#{@uuid}", (msg) =>

            # First check for errors
            # TODO: in future notify listeners to
            # get better interactivity
            handleError = (err) =>
                log.error("Error receiving message to zync object #{@domain}/#{@uuid}", err)

                # We know the server image will be initialized from the schema
                initializeFromSchema()

            # Only used by purescript
            if msg == 'access_denied'
                updateErrorListeners(@listeners, {type: 'access_denied'})
                return
            else if msg.substring(0, 6) == 'error|'
                err = msg.substring(6)
                updateErrorListeners(@listeners, {type: 'error', value: err})
                return

            # Receive a message from the server
            result = undefined
            try
                result = deepFreeze JSON.parse(msg)
            catch e
                handleError('unknown_server_error')
                return
            if result.activePeers? or result.oneToOne?
                # Route peer messages to purescript
                frpPeerChannel(@domain, @uuid, result)
            else if result.image?
                log.debug "#{@domain}/#{@uuid} Received image #{JSON.stringify result.image} for #{@domain}/#{@uuid}"
                # Initial SO state sent from server
                # {historyStart: Number, history: [Commit], state: JSON}

                # Now we need to reintegrate local commits
                oldServerHistoryLength = @historyStart + @serverHistory.length
                newServerHistoryLength = result.history.length + result.historyStart
                commits = (obj for obj in result.history).reverse()

                if !@localImage?
                    # This is initial load from server
                    log.info "#{@domain}/#{@uuid} received initial image from server"
                    initializeFromImage(result.historyStart, result.image)
                    @receiveFromServer(commits...)
                    updateImageListeners(@listeners, @localImage, @vs())
                else
                    # Received an image update when already initialized
                    if oldServerHistoryLength > newServerHistoryLength
                        # Something has gone horribly wrong on the server, we need to reset this SO state
                        log.warn 'Server data loss: server sent shorter history than already confirmed'
                        initializeFromImage(result.historyStart, result.image)
                        @receiveFromServer(commits...)
                        updateImageListeners(@listeners, @localImage, @vs())
                    else if result.historyStart > oldServerHistoryLength
                        log.info("#{@domain}/#{@uuid} received image from server, re-initializing local state")
                        if oldServerHistoryLength > 0
                            # Server hasn't sent enough history to transform local commits.
                            # We must abandon all local commits
                            # FUTURE: consider re-requesting history instead?
                            log.warn "Dropped all local history for #{@uuid} because server history doesn't go back far enough"
                        initializeFromImage(result.historyStart, result.image)
                        @receiveFromServer(commits...)
                        updateImageListeners(@listeners, @localImage, @vs())
                    else
                        # We can transform local history against new commits supplied by the server
                        # *** This is the mainline ***
                        log.info("#{@domain}/#{@uuid} received image and new commits from server, integrating with local state")
                        unseenServerCommits = _.drop(commits, oldServerHistoryLength - result.historyStart)

                        # Add the new server commits to our history, and calculate corrective
                        # commits that bring our local image in line with the server
                        transformedCommits = @receiveFromServer(unseenServerCommits...)
                        updateCommitListeners(@listeners, transformedCommits, @localImage, @schema, @vs())
            else
                # No image in result from server
                # Array of commits or one commit sent by server
                unless @schema? and @serverImage?
                    throw new Error "Received commit from server before image for #{@domain} #{@uuid}"
                commits = if _.isArray(result) then result.reverse() else [result]
                transformedCommits = @receiveFromServer(commits...)
                if transformedCommits.length > 0 # No listener update if we just confirmed existing commits
                    updateCommitListeners(@listeners, transformedCommits, @localImage, @schema, @vs())
            #log.debug "Server updated #{@uuid} to #{JSON.stringify @serverImage}"
            @trySendingNextCommit() # Now we've connected we can start updating the server

        # Request the state immediately
        # The websocket should send the request when connection is opened

        # Add a domain route if it doesn't exist
        unless routes[@domain]?
            # Add a route for this domain
            # This is used to create new Zync objects on this domain
            routes[@domain] = wsrouter.addRoute(@domain, ->)

        # Ask for the history of this object and subscribe to updates
        # Only when we receive the state update do we attempt to send new commits
        unless clientImage?
            subscribeMsg =
                if @serverHistory.length == 0
                    if _.isNumber(maxVs)
                        @uuid + '!' + maxVs
                    else
                        @uuid
                else
                    requestFrom = @historyStart + @serverHistory.length
                    @uuid + '!' + requestFrom
            routes[@domain].send(subscribeMsg)

        # Immediately start a destruction check
        # This ensures we clean up if someone starts an object and never
        # calls onChange
        @checkForTermination()

        this

    ZyncState :: fetchEarlierHistory = (maxVs) ->
        # Set object to uninitialized and re-fetch history
        @unappliedOps  = [] # Array of operations yet to be appended
        @localHistory  = [] # Mutable array
        @localImage    = undefined
        @historyStart  = 0  # History may not extend back to object creation
        @sentToServer  = 0  # Number of commits from localHistory
        @serverHistory = []
        subscribeMsg = @uuid + '!' + maxVs
        routes[@domain].send(subscribeMsg)

    ZyncState :: checkForTermination = ->
        destructionCheck = =>
            if @listeners.length == 0 and !@frpListener? and !@isLocal and !@dormant
                key = @domain + '/' + @uuid
                log.info("Sending unsubscribe from #{key}")
                @commitRoute.send "unsubscribe"
                # Keep state and wait in case this object is needed again
                @dormant = true

        # Delay the destruction check to account for
        # quick subscribe / unsubscribes as the object is
        # being set up
        _.delay(destructionCheck, 2000)

    ZyncState :: trySendingNextCommit = ->
        if @isLocal then return
        if @sentToServer == 0 and @localHistory.length > 0
            # Batch in groups of 10
            batchSize = 10
            commits = _.take(@localHistory, batchSize)

            #log.debug "Sending commit #{commitToString commit} to server"
            @commitRoute.send(JSON.stringify commits)
            @sentToServer += commits.length

    ZyncState :: commit = (vs, ops...) ->

        # Check preconditions
        if !userId?
            throw new Error 'Attempt to commit operation without active user'

        # Use latest vs if no vs passed
        vs ?= @vs()

        unless _.isNumber(vs)
            throw new Error "Attempt to commit to version vs=#{vs}!"

        # The first op applied is at the beginning of ops
        ops = @unappliedOps.concat(ops)
        if ops.length == 0
            return
        @unappliedOps = []

        # Transpose ops over each other to linearize them
        transposedOps = []
        for opToTranspose in ops
            for op in transposedOps
                opToTranspose = transposeJsonOp(opToTranspose, op)[0]
            transposedOps.push opToTranspose

        # Must reverse the arguments of compose, which takes the last applied op last
        composedOp = normalizeJsonOp _.foldl(transposedOps, ((b, a) => composeJsonOps(a, b, @schema)))

        # If passed vs isn't head, then we need to transpose over remaining history
        nToTake = @vs() - vs
        transposeAgainst =
            if nToTake == 0
                # Most common situation, we committed to head
                []
            else if nToTake < 0
                # Committed to the future (!)
                throw new Error "Attempt to commit to the future for #{@domain} #{@uuid}"
            else if nToTake <= @localHistory.length
                # Committed to a point not yet sent to server
                _.drop(@localHistory, @localHistory.length - nToTake)
            else
                # Committed earlier than server head
                _.drop(@serverHistory, @serverHistory.length + @localHistory.length - nToTake).concat(@localHistory)

        for commit in transposeAgainst
            try
                op1 = rootOpToString(composedOp)
                op2 = rootOpToString(commit.op) # This one sometimes not normalized correctly!
                log.info "Transforming #{op1} against #{op2}"
            catch e
                log.error "Could not stringify ops: #{JSON.stringify commit.op} against #{JSON.stringify composedOp}"
            [composedOp, newOp] = transposeJsonOp(composedOp, commit.op)
            composedOp = normalizeJsonOp composedOp

        log.debug "Committing #{rootOpToString(composedOp)} to #{@uuid}"

        # Create a commit for the composed op
        now = Date.now() # Milliseconds since 1970 UTC
        commit =
            uuid: @uuid
            vs: @vs()
            op: composedOp
            author: userId
            created: now

        @localImage = updateImage([commit], @localImage, @schema)
        @localHistory.push commit
        updateCommitListeners(@listeners, [commit], @localImage, @schema, @vs())

        # If we got here with no errors, try sending the operations to the server
        @trySendingNextCommit()

    ZyncState :: peerMessage = (toPage, contents) ->
      # FUTURE: queue with commits, retry etc?
      @commitRoute.send("peer|" + toPage + "|" + JSON.stringify(contents))

    listenerIdGen = 0

    ZyncState :: onChange = (onCommit, onImage, onError) ->
        # If image handler not provided, use onCommit instead
        # Passed vs is image version, not op version
        onImage ?= (image, vs) -> onCommit(image, undefined, vs)
        onError ?= (->)
        id = listenerIdGen++
        listener = {id, onCommit, onImage, onError}
        if @dormant == true
            # Re-subscribe when a dormant object is needed again
            maxVs = @serverHistory.length
            @fetchEarlierHistory(maxVs)
            @dormant = false
        @listeners.push listener
        id

    ZyncState :: unsubscribe = (listenerId) ->
        @listeners = @listeners.filter (listener) ->
            listener.id != listenerId

        # Defer the final server unsubscribe
        @checkForTermination()


    # Integrate a commit received from the server, and adjust
    # local history to match.
    # Returns the server operation transformed by local history
    ZyncState :: receiveFromServer = (commits...) ->

        # Make sure all ops are immutable
        transformedCommits = []
        for commit in commits

            # Check that this update is valid
            unless commit.vs == @serverHistory.length + @historyStart
                # Server and client are out of sync.
                # This currently occurs with > 1 client per user connected
                throw new Error(
                    "#{@domain}/#{@uuid} received illegal commit " +
                    "from server #{commitToString(commit)}, " +
                    " history length #{@serverHistory.length + @historyStart}")

            op = commit.op

            # Update server history
            @serverHistory = @serverHistory.concat [commit]
            @serverImage = updateImage([commit], @serverImage, @schema)
            # If op is exactly what we sent to the server
            # we are home and dry
            if @sentToServer > 0 and _.isEqual(op, @localHistory[0].op)
                log.debug "Received confirmation of commit #{commitToString commit} from server"
                # Confirmation of an op we sent to the server earlier
                # Just shift it from local to server history
                @sentToServer -= 1
                @localHistory.shift()
            else
                # This must be a new update from another client
                # Transform local history against it
                # Then apply it
                log.debug "#{@domain}/#{@uuid} adds #{commitToString commit} from server"

                newLocalHistory = []
                for localCommit in @localHistory
                    newLocalCommit = clone(localCommit)
                    newLocalCommit.vs += 1
                    [newLocalOp, op] = transposeJsonOp(localCommit.op, op)
                    newLocalCommit.op = normalizeJsonOp(newLocalOp)
                    newLocalHistory.push newLocalCommit
                @localHistory = newLocalHistory
                # Regenerate local image from server image
                @localImage   = updateImage(@localHistory, @serverImage, @schema)
                transformedCommit = clone commit
                transformedCommit.op = op
                transformedCommits.push transformedCommit

        transformedCommits

    ZyncState :: vs = ->
        @localHistory.length + @serverHistory.length + @historyStart

    ZyncState :: updatesSince = (oldVs) ->
        if oldVs < @historyStart
            # TODO: load history from server here?
            throw new Error 'Attempt to get updates from before history started'
        n = @vs() - oldVs
        if n > @localHistory.length
            # Include some server history
            _.drop(@serverHistory, oldVs).concat(@localHistory)
        else
            # Everything needed in local history
            _.drop(@localHistory, @localHistory.length - n)

    ZyncState :: imageAtVs = (vs) ->
        if vs < @historyStart
            throw new Error 'Attempt to get image from before history start'
        else
            image = clone(@startImage)
            updateImage(_.take(@serverHistory, vs - @historyStart), image, @schema)

    # An API for applying operations to part of a zync object
    class Path

        # Set up private state
        # soState,
        constructor: (soState, @path) ->

            # Check path validity
            if _.isString(@path)
                @path = @path.split('.')
            if !_.isArray(@path)
                throw new Error "Path must be a string or array, found #{@path}"

            @listeners = []

            vs = soState.vs()

            # TODO: cope with rewriting of history ?
            validate = =>
                newVs = soState.vs()
                if newVs > vs and vs >= soState.historyStart
                    updates = _.pluck(soState.updatesSince(vs), 'op')
                    try
                        for update in updates
                            if @path.length > 0
                                @path = transposePath('JsonOp', update, @path)
                        vs = newVs
                    catch e
                        if e.message == 'Invalidated this path'
                            @path = undefined
                        else
                            throw e

            @image = ->
                validate()
                if !@path? then throw new Error 'Path invalidated by other operations'
                image = soState.localImage
                for el in @path
                    if !image?
                        throw new Error "Couldn't find #{@path} in #{JSON.stringify soState.localImage}"
                    image = image[el]
                image

            @isValid = ->
                validate()
                @path?

            @isLoaded = ->
                soState.localImage?

            stateListenerId = undefined

            @onChange = (onCommit, onImage, onError) ->
                onImage ?= (image, vs) -> onCommit(image, undefined, vs)
                onError ?= (->)
                if @listeners.length == 0
                    onCommitP = (newImage, op, vs) =>

                        # Validate this path
                        validate()
                        if !@path? then return

                        # Find op at this path
                        for pathEl in @path
                            op = op.at(pathEl)
                            if !op? then return

                        image = @image()
                        for listener in @listeners
                            # Only send updates if op is defined
                            if op?
                                listener.onCommit(image, op, vs)
                    onImageP = (image, vs) =>
                        image = @image()
                        for listener in @listeners
                            listener.onImage(image, vs)
                    onErrorP = (err) =>
                        for listener in @listeners
                            listener.onError(err)
                    stateListenerId = soState.onChange(onCommitP, onImageP, onErrorP)
                id = listenerIdGen++
                listener = {id, onCommit, onImage, onError}
                @listeners.push listener
                return id


            @unsubscribe = (idToUnsubscribe) ->
                @listeners = @listeners.filter (listener) ->
                    listener.id != idToUnsubscribe
                if @listeners.length == 0 and stateListenerId?
                    soState.unsubscribe(stateListenerId)
                return this

            @createOp = (opType, op) ->
                image = soState.localImage
                pathWithImage = for el in _.initial(@path)
                    image  = image[el]
                el = _.last(@path)
                newOp = createOp(opType, op, el, image)
                @addRawOp(newOp)
                return this

            @addRawOp = (op) ->
                image = soState.localImage
                pathWithImage = for el in @path
                    result = [el, image]
                    image  = image[el]
                    result
                for [el, image] in _.initial(pathWithImage).reverse()
                    opType =
                        if _.isArray(op)
                            'Splice'
                        else if _.isObject(op)
                            'JsonOp'
                        else
                            throw new Error 'Unknown recursive op type #{op}'
                    op = createOp(opType, op, el, image)
                soState.unappliedOps.push op
                return this

            @commit = ->
                soState.commit()
                return this

            # Create a sub-path from this path
            @at = (subpath...) ->
                new Path(soState, @path.concat subpath)

            @domain = soState.domain
            @uuid = soState.uuid

            @pathId = @uuid + "|" + @path.join('.')

            @vs = -> soState.vs()

            # Utility method which ensures the object is loaded
            # before passing the image
            # if defined, vs defines the version to fetch
            @run = (fn) =>
                unless _.isFunction(fn)
                    throw new Error 'Call run with a callback function'
                if @isLoaded()
                    # TODO should pass update instead of ID
                    fn(@image(), soState.vs())
                else
                    listenerId =
                        @onChange(
                            (->),
                            ((image, vs) =>
                                @unsubscribe(listenerId)
                                _.defer (-> fn(image, vs))
                            ),
                            ((err) =>
                                @unsubscribe(listenerId)
                                _.defer (-> fn(err))
                            )
                        )
            @imageAtVs = (fn, vs) =>
                if vs < soState.historyStart
                    soState.fetchEarlierHistory(vs)
                @run ->
                    # ignore value returned by run
                    # TODO follow path to get image
                    fn(soState.imageAtVs(vs))

            # Function for quickly viewing Zync history
            @debugHistory = =>
                for opRaw in soState.serverHistory.map((x) -> x.op)
                    op = new Op('JsonOp', opRaw)
                    for pathEl in @path when op?
                        op = op.at(pathEl)
                    if op?
                        log.info(op.toString())

        # FOR UNIT TESTING ONLY
        commitRawOp: (op) ->
            @addRawOp(op)
            @commit()

        # Define the convenience methods for creating ops
        update:  (x...) -> @setOp('Update')(x...)
        replace: (x...) -> @setOp('Replace')(x...)
        nullify: -> @createOp('Update', null)
        setOp:   (opType) -> (value) =>
            unless value?
                throw new Error 'Please use nullify'
            @createOp(opType, value)

        incr: (n) ->
            unless _.isNumber(n) and Math.floor(n) == n
                throw new Error "Non-integer #{n} passed to incr"
            @createOp('Incr', n)

        # Define convenience methods for arrays and strings
        append: (value) ->
            image = @image() ? []
            @insert(image.length, value)
        insert: (index, value) ->
            @splice(index, 0, value)
        delete: (index, nDelete) ->
            @splice(index, nDelete, if _.isArray(@image()) then [] else '')
        removeOne: (pred) ->
            predFn =
                if _.isFunction(pred)
                    pred
                else
                    (x) -> x == pred
            index = _.findIndex(@image(), predFn)
            if index >= 0
                @delete(index, 1)
        splice: (index, nDelete, value) ->

            # Check parameters are legal
            obj = @image()
            unless obj? and _.isNumber(index) and 0 <= index <= obj.length - nDelete
                throw new Error "Invalid splice at #{index}, length #{nDelete}, path #{@path}, on array #{JSON.stringify obj}"
            if (_.isString(obj) and !_.isString(value)) or (_.isArray(obj) and !_.isArray(value))
                throw new Error "Attempt to illegally insert #{JSON.stringify value} at #{@path} into #{JSON.stringify obj}"

            # Create the splice operation
            spliceOp = []
            if index > 0 then spliceOp.push index
            spliceOp.push
                d: nDelete
                i: value
            l = value.length
            if index + nDelete < obj.length
                spliceOp.push (obj.length - index - nDelete)

            @createOp('Splice', spliceOp)

        # Utility methods for use with Angular
        bindToScope: (scope, name, transformFn = _.identity) ->
            # Trigger scope digest if appropriate
            safeApply = (fn) ->
                if scope.$$phase || scope.$root.$$phase then fn()
                else scope.$apply fn

            # Check that this name isn't already bound on the scope
            scope.$$zyncBindings ?= {}
            binding = scope.$$zyncBindings[name]
            if binding?
                # unsubscribe the old binding
                binding.z.unsubscribe(binding.id)

            # Copy the image to the scope
            applyChanges = (image) -> safeApply ->
                if image? then scope[name] = transformFn(clone(image))

            # Listen to changes
            listenerId = @onChange applyChanges

            # Copy the image straight away if it exists
            applyChanges(@image())

            # Record the binding on the scope so we
            # don't accidently bind more than one object
            # to the same scope variable
            scope.$$zyncBindings[name] =
                z: this
                id: listenerId

            # Unsubscribe when scope is destroyed
            scope.$on '$destroy', =>
                @unsubscribe(listenerId)
                delete scope.$$zyncBindings[name]




    # API for querying ops
    class Op

        constructor: (@opType, @op) ->
            # Parameter checking
            if @opType in ['Update', 'Replace']
                @value = @op
            else if !@opType? or !@op?
                # Id op
            else if @opType == 'JsonOp'
                unless _.isObject(@op) then throw new Error 'Illegal JsonOp'
            else if @opType == 'Splice'
                unless _.isArray(@op) then throw new Error 'Illegal Splice'
                @preLenSum  = 0
                @postLenSum = 0
                for spliceOp in @op ? []
                    @preLenSum  += preLen(spliceOp)
                    @postLenSum += postLen(spliceOp)
            else
                throw new Error 'Illegal Op'

        keys: ->
            if @opType == 'JsonOp'
                _.map _.keys(@op), (key) -> opOf(key)[1]
            else if @opType == 'Update' or @opType == 'Replace'
                _.keys(@op)
            else
                throw new Error 'can only get keys for JsonOp'


        # In future, generalize to general op traversal?
        innerOps: -> # fn takes item and index
          if @opType == 'Splice'
            res = [] # Sparse array
            i = 0
            j = 0
            while i < @op.length
              oi = @op[i]
              l = preLen(oi)
              if isModify(oi)
                [oiType, innerOp] = parseModify(oi)
                res.push(@at(j))
              i += 1
              j += l
            return res
          else if !@opType?
            # No modifications
            return []
          else
            throw new Error('innerOps called on ' + @opType + ', ' + @toString())


        at: (prop) ->
            if @opType == 'JsonOp' and _.isString(prop)
                keys = _.filter _.keys(@op), (key) -> opOf(key)[1] == prop
                if keys.length == 0
                    undefined
                else
                    key = keys[0]
                    innerOp = @op[key]
                    opType  = opOf(key)[0]
                    new Op(opType, innerOp)
            else if @opType in ['Update', 'Replace'] and _.isNumber(prop) or _.isString(prop)
                if @op? and @op[prop]?
                    new Op(@opType, @op[prop])
                else
                    undefined
            else if @opType == 'Splice' and _.isNumber(prop)
                i = 0
                j = 0
                loop
                  oi = @op[i]
                  l = preLen(oi)
                  if j <= prop < j + l
                    if isModify(oi)
                      [oiType, innerOp] = parseModify(oi)
                      return new Op(oiType, innerOp)
                    else
                      return undefined
                  else
                    i += 1
                    j += l
            else
                throw new Error "Illegal call to at with opType #{@opType}, property #{prop}"

        dl: ->
            unless _.isArray(@op) then throw new Error 'dl only valid for array ops'
            @postLenSum - @preLenSum

        updatedRange: ->
            # Assumes normalized op, ie only one keep operation at beginning / end
            # of slice
            unless @opType == 'Splice' then throw new Error 'range only valid for array ops'
            getOr0 = (x) -> if _.isNumber(x) then x else 0
            a0 = getOr0(_.head(@op))
            a1 = getOr0(_.last(@op))
            [a0, @preLenSum - a1]

        isReplace: ->
            @opType == 'Update' or @opType == 'Replace'

        toString: ->
            opToString(@opType, @op)

        insertions: ->
            inserted = []
            if @opType == 'Splice'
                for spliceOp in @op
                    if _.isObject(spliceOp) and spliceOp.i?
                        inserted.push el for el in spliceOp.i
            return inserted


    # Return static method API
    api = {
        # On log in
        changeUser: (newUserId) -> userId = newUserId

        # Provide zync data fetched from server or localstorage
        initialize: (domain, uuid, clientImage) ->
            log.debug('initialize ' + domain + ' - ' + uuid)
            key = domain + '/' + uuid
            if !state[key]?
                log.debug("Initializing zync object #{domain}: #{uuid}")
                state[key] = new ZyncState(domain, uuid, isCreate = true, isLocal = false, clientImage)
            else
                log.error("Attempted to initialized existing object #{domain} #{uuid}")
            @subscribe(domain, uuid, false, true) # not local, server already subscribed
            new Path(state[key], [])



        fetch: (domain, uuid, isLocal = false, maxVs = undefined) ->
            # Check parameters
            isCreate = !uuid?
            uuid ?= generateUuid()
            key = domain + '/' + uuid
            unless ZyncUtils.isUUID(uuid)
                throw new Error "Invalid UUID #{uuid}"

            # Create object if this is first subscribe
            if !state[key]?
                log.debug("Fetching zync object #{domain}: #{uuid}")
                state[key] = new ZyncState(domain, uuid, isCreate, isLocal, maxVs)

            # Return path
            new Path(state[key], [])


        # This is mainly for testing purposes
        stateOf: (domain, uuid) ->
            clone(state[domain + '/' + uuid])


        fnsForUnitTests:
            normalizeJsonOp: normalizeJsonOp
            transpose: transpose
            compose: compose
            apply: apply


        setFRPImageChannel: (channel) ->
            frpImageChannel = (c) ->
                if c.image?
                    channel(c)


        setFRPPeerChannel: (channel) ->
            frpPeerChannel = channel


        # To be used by frp functions: does fetch and subscribes frp listener
        subscribe: (domain, uuid, isLocal = false) ->
            # Do fetch, and discard returned Path API, as PS does not use it
            path = @fetch(domain, uuid, isLocal)
            uuid = path.uuid
            key = domain + '/' + uuid

            # Add subscriber to prevent cleanup
            unless state[key].frpListener?
                onCommit = (image, op, vs) ->
                  frpImageChannel
                      domain: domain
                      uuid: uuid
                      image: image
                      vs: vs
                      op: {typ: op.opType, value: op.op} # Raw op info

                # Only zyncFetch deals with images and errors
                onImage = (->)
                onError = (->)

                state[key].frpListener =
                    state[key].onChange(onCommit, onImage, onError)

            return uuid

        unsubscribe: (domain, uuid) ->
            key = domain + '/' + uuid
            unless ZyncUtils.isUUID(uuid)
                throw new Error "Invalid UUID #{uuid}"
            if state[key]? and state[key].frpListener?
                state[key].unsubscribe(state[key].frpListener)
                delete state[key].frpListener

        querySubscribe: (domain, query) ->
            # Subscribe to a query of this domain's objects
            wsrouter.sendRaw(domain, "query|" + JSON.stringify(query))

        commit: (domain, uuid, vs, op) ->
            # Check params
            key = domain + '/' + uuid
            unless ZyncUtils.isUUID(uuid)
                throw Error "Invalid UUID #{uuid}"
            if state[key]?
                # Run commit immediately
                state[key].commit(vs, op)
            else
                # First subscribe to the object, then commit
                @fetch(domain, uuid).run ->
                    state[key].commit(vs, op)

        peerMessage: (domain, uuid, toPage, contents) ->
            key = domain + '/' + uuid
            unless state[key]
                throw Error "Cannot find object #{key} to send peer message #{JSON.stringify contents}"
            state[key].peerMessage(toPage, contents)

    }
    return api
