var ZyncUtils;
ZyncUtils = {
  javaHashCode: function(str){
    var hash, l, i$, i, c;
    hash = 0;
    l = str.length;
    if (l === 0) {
      return hash;
    }
    for (i$ = 0; i$ < l; ++i$) {
      i = i$;
      c = str.charCodeAt(i);
      hash = (hash << 5) - hash + c | 0;
    }
    return hash;
  },
  hexHashCode: function(text){
    return (ZyncUtils.javaHashCode(text.trim()) >>> 0).toString(16);
  },
  isUUID: function(text){
    return /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/.test(text);
  }
};

