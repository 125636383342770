# TODO update this for many zync comoponents
globalEventManifest = {}

globalRegisterEvent = (docId) ->
    events = {}
    globalEventManifest[docId] = events
    (name, fn) ->
        events[name] = fn

globalUnregister = (docId) ->
    delete globalEventManifest[docId]

broadcast = (name, args...) ->
    for k, events of globalEventManifest
        events[name]?(name, args...)

ZyncTextRender =
    idsToCursors: (ids) -> _.map(ids, (id) -> 'cursor' + id)

    isCorrection: (id) -> id.substring(0,10) == 'correction'

    render: (zyncTextEl, authors, mentors, doc, disableGrammarChecking, cursor = '', getLanguageHint = (-> ''))->
        authorCursors     = ZyncTextRender.idsToCursors(authors)
        mentorCursors     = ZyncTextRender.idsToCursors(mentors)
        correctionNLPData = doc.correctionNLPData()
        zyncTextEl.html("")
        spans    = doc.spans()
        newNodes = (ZyncTextRender.spanToHTML(span, doc, cursor, authorCursors, mentorCursors, correctionNLPData, disableGrammarChecking, getLanguageHint) for span in spans)
        for node in newNodes
            zyncTextEl.append(node)
        spans

    spanToHTML: (span, doc, cursor, authorCursors, mentorCursors, correctionNLPData, disableGrammarChecking, getLanguageHint = (-> ''),  isAnswerForCorr = '') ->
        # Cache this?
        sentenceNLPData = if doc.sentenceNLPData? then doc.sentenceNLPData() else {}

        # Argument check
        unless _.isString(cursor) and _.isObject(span) and
               span.id? and _.isArray(authorCursors) and
               _.isArray(mentorCursors) and _.isString(isAnswerForCorr) and
               _.isObject(correctionNLPData) and
               _.isFunction(getLanguageHint)
            throw new Error "Bad invocation of spanToHTML with cursor #{cursor}"
        id   = span.id
        text = span.text

        focusData = isAnswerForCorr + '|' + span.start

        # Deal with the case of inner spans
        if _.isArray(span.innerSpans)
            baseSpan = $('<span id='+id+" data-focus=\"#{focusData}\" style=\"white-space: nowrap; display: inline-block\"></span>")
            for innerSpan in span.innerSpans
                baseSpan.append(ZyncTextRender.spanToHTML(innerSpan, doc, cursor, authorCursors, mentorCursors, correctionNLPData, disableGrammarChecking, getLanguageHint, isAnswerForCorr))
            return baseSpan

        # Swap spaces for unicode symbol to make more obvious
        space = new RegExp(' ', 'g')
        if isAnswerForCorr != '' #or _.find(span.annotations, isCorrection)?
            text = text.replace(space, '⎵')


        # Otherwise, deal with normal span
        linkId   = _.find(span.annotations, (anno) -> /a[0-9]+/.test(anno))
        tagName =
            if linkId? then 'a'
            else 'span'
        baseSpan = $(document.createElement(tagName))
        if linkId?
            href = doc.getAnno(linkId)?.href
            baseSpan.attr('href', href)
        baseSpan.text(text)
        baseSpan.attr('id', id)
        baseSpan.attr('data-focus', focusData)
        baseSpan.text(text)
        for anno in span.annotations
            baseSpan.addClass anno
            # Quick hack to get styles for gaps
            withoutNumbers = anno.replace /[0-9]/g,  ''
            if withoutNumbers != anno
                baseSpan.addClass withoutNumbers

        if !span.onDestroy? then span.onDestroy = []

        [c0, c1]  = doc.rangeOf(cursor)
        corr      = span.startOf

        hideCorrection = (corrId) -> cursor in authorCursors and !(correctionNLPData[corrId] in ['checked', 'ok'])

        # Styling for the start of a correction
        if corr? and ZyncTextRender.isCorrection(corr) and !hideCorrection(corr)
            # Some convenience variables
            [corrStart, corrEnd] = doc.rangeOf corr
            path       = doc.so.at('corrections').at(corr)
            corrDoc    = new StyledText(path)
            corrObj    = path.image()
            corrLength = corrObj.text?.length ? 0

            baseSpan.addClass 'correction-start'

            if cursor in authorCursors
                correctionPopups = $('<div class="hint-popups"></div>')

                # Add correction hint popup for authors if the correction contains the cursor
                spanContainsCursor = c0 >= corrStart && c1 <= corrEnd
                hint = corrObj.hint
                if spanContainsCursor and _.isObject(hint)
                    hintText = translations[hint.id]
                    if _.isFunction(hintText)
                        hintText = hintText(hint)
                    popup = $("<popup><span>#{hintText}</span></popup>")
                    correctionPopups.append(popup)


                # Add an insert line and arrow if the current answer is 0-length
                if corrStart == corrEnd
                    state = doc.answerState(corr, '')
                    if state == 'correct'
                        baseSpan.addClass state
                    else
                        correctionPopups.append $('<insertarrow></insertarrow>')
                    baseSpan.addClass 'correction-start-with-border'

                # Only add the popups if there are any
                if correctionPopups.children().length > 0
                    baseSpan.append(correctionPopups)

            # Add a correct answer editor for mentors
            currentAttempt = doc.text(doc.rangeOf corr)
            if cursor in mentorCursors and corrLength > 0 and doc.answerState(corr, currentAttempt) != 'correct'
                baseSpan.addClass 'correction-start-with-answer'
                classes = ['correction-answer']
                nlpData = correctionNLPData[corr]
                corrNLPClass = switch nlpData
                    when 'checked' then 'ok' # Same as OK
                    when 'ok' then 'ok'
                    when 'processing' then 'processing'
                    else 'errors'
                classes.push("correction-answer-#{corrNLPClass}")
                correctionNode = $("<div corr=\"#{corr}\" class=\"#{classes.join(' ')}\"></div>")
                corrSpans = corrDoc.spans()
                for corrSpan in corrSpans
                    # Corrections cannot themselves have corrections, so no correction data passed to recursion
                    correctionNode.append ZyncTextRender.spanToHTML(corrSpan, doc, cursor, authorCursors, mentorCursors, {}, disableGrammarChecking, getLanguageHint, corr)

                # Add a button to delete the correction
                if corrDoc.rangeOf(cursor).length > 0
                    deleteButton = $('<cross-button class="correction-delete-button"></cross-button>')
                    deleteButton.on "mousedown", ->
                        doc.removeCorrection(corr).commit()
                    correctionNode.append deleteButton

                baseSpan.append correctionNode

        # Add cursor classes
        for annoId in span.annotations
            if annoId in authorCursors
                baseSpan.addClass 'cursor-student'
            else if annoId in mentorCursors
                baseSpan.addClass 'cursor-mentor'

        if span.startOf?
            annoId = span.startOf
            if annoId.substring(0,6) == 'cursor'
                if annoId in authorCursors
                    baseSpan.addClass 'cursor-student-start'
                else if annoId in mentorCursors
                    baseSpan.addClass 'cursor-mentor-start'
                # Allow finding the div using getElementsByClassName
                baseSpan.addClass annoId

        # Add stylings for sentence endings
        if span.startOf == "s" and (not disableGrammarChecking) and isAnswerForCorr == '' # Not for correction answers
            inSentence = if span.start == 0 then 0 else span.start - 1
            [sentenceStart, sentenceEnd] = doc.sentenceAround(inSentence) # Range overflow issues
            correctionData = doc.rangeCorrectionData(sentenceStart, sentenceEnd, correctionNLPData)
            switch correctionData
                when 'correct'
                    nlpData  = sentenceNLPData[span.start + span.text.length]
                    if nlpData? then switch nlpData
                        when 'checked'
                            baseSpan.addClass 'sentence-end-checked'
                        when 'ok'
                            baseSpan.addClass 'sentence-end-ok'
                        when 'processing'
                            baseSpan.addClass 'sentence-end-processing'
                        else
                            baseSpan.addClass 'sentence-end-errors'
                            # Add popup with hint for wrong sentence
                            hint = getLanguageHint(nlpData)
                            hintPopup = $('<div class="hint-popups"><popup><span>' + hint + '</span></popup></div>')
                            baseSpan.append(hintPopup)
                when 'unknown'
                    baseSpan.addClass 'sentence-end-processing'
                when 'correcting'
                    baseSpan.addClass 'sentence-end-correcting'
                when 'incorrect'
                    baseSpan.addClass 'sentence-end-corrected-errors'

        # Add stylings for text body
        else if span.text.length > 0 and (not disableGrammarChecking)
            inSentence = if span.start == doc.length() - 1 then span.start else span.start + 1
            [sentenceStart, sentenceEnd] = doc.sentenceAround(inSentence) # Range overflow issues
            correctionData = doc.rangeCorrectionData(sentenceStart, sentenceEnd, correctionNLPData)
            # Find the corresponding sentence data for this span
            nlpData = 'processing'
            for index, v of sentenceNLPData
                if index >= span.start + span.text.length
                    nlpData = v
                    break
            switch correctionData
                # Styles for text spans
                when 'correct' then switch nlpData
                    when 'checked', 'ok'
                        baseSpan.addClass 'sentence-ok'
                    when 'processing'
                        baseSpan.addClass 'sentence-processing'
                    else
                        baseSpan.addClass 'sentence-errors'
                else
                    # No special classes for spans in a corrected sentence

        # Add styles for the author's text in corrections
        for annoId in span.annotations
            # Hide corrections that haven't been checked as OK
            if ZyncTextRender.isCorrection(annoId) and !hideCorrection(annoId)
                baseSpan.addClass "correction"
                switch correctionNLPData[annoId]
                    when 'checked', 'ok'
                        baseSpan.addClass "correction-#{doc.answerState(annoId)}"
                    else
                        baseSpan.addClass "correction-answer-editing"

        baseSpan


ZyncText = (zyncTextEl, so, userId, authors, mentors, disableGrammarChecking, translations, getLanguageHint, areYouSure, notify = (->), langCode) ->

    uuid = zyncTextEl[0].getAttribute('data-uuid')
    cursor = 'cursor' + userId
    role   = undefined
    doc    = undefined
    spans  = []

    # All state is declared here
    behaviour = {}

    # Disguise a custom event as a paste event to pass cleanly
    # through react and pux event mechanisms
    dispatchEvent = (type, value) ->
        event = null
        try
            event =
                new CustomEvent 'paste',
                    detail:
                        type: type
                        value: value
                    bubbles: true
        catch e
            event = document.createEvent('Event')
            event.detail =
                type: type
                value: value
            event.initEvent('paste', true, true)

        zyncTextEl[0].parentNode?.dispatchEvent(event)

    getRole = ->
        if _.contains(authors, userId)
          'author'
        else if _.contains(mentors, userId)
          'mentor'
        else
          ''


    psKeyboardSendUnThrottled = (char, lang) ->
        PS.keyboardExternalInput.set
            prevChar: char
            lang: lang
    psKeyboardSend = _.debounce(psKeyboardSendUnThrottled, 50)
    psKeyboardCancel = -> psKeyboardSend(' ', '')

    registerEvent = globalRegisterEvent(uuid)

    isCorrection = ZyncTextRender.isCorrection
    overlaps     = ( [x0, x1], [y0, y1] ) -> x1 > y0 and x0 < y1
    generateId   = (existingIdMap) ->
        corrId = undefined
        while !corrId? or existingIdMap["correction"+corrId]?
            corrId = Math.floor(Math.random() * 1000000)
        corrId

    notifyErrors = (fn) -> (args...) ->
        try
            fn(args...)
        catch err
            console.error('Error in ZText render, user notified: ' + err)
            notify(translations.unknown_server_error)

    maxCharsToDeleteWithNoWarning = 30


    # Function and state to auto-submit edited corrections
    corrTimeouts = {}
    checkForEditedCorrections = (doc, [c0, c1]) ->
        correctionEditTimeout = 3000
        unless c0? and c1? then return
        c1 = Math.min(c1, doc.length())
        editedCorrections = _.filter( doc.annosAt(c0, c1), isCorrection )
        for correctionId in editedCorrections
            randomId = generateId(existingIdMap = doc.so.image().corrections)
            corrTimeouts[correctionId] = randomId
            doAttempt = ->
                if corrTimeouts[correctionId] == randomId
                    requestHint(doc, correctionId)
                    delete corrTimeouts[correctionId]
            _.delay(doAttempt, correctionEditTimeout)

    # Check to see if this edit is too close to a correction to be allowable
    editTooCloseToCorrection = (doc, c0, c1, d0, d1, cursor, notify) ->

        start = [c0, d0]
        end   = [c1, d1]

        # If this isn't a correctable doc, ignore
        unless doc.rangeNLPData?
            return false

        # Expand the range to include the space before or after a sentence
        [x, x, e0, e1] = doc.expandRange([c0, c1, d0, d1], 0)

        # Get the NLP data
        nlpData = doc.rangeNLPData(c0, c1, e0, e1)

        # Always allow editing at the end of the document
        if c0 == doc.length()
            return false

        # Allow editing if the correction doesn't pass muster
        if !(nlpData.correctedNLP in ['ok', 'checked'])
            return false

        # Allow editing if there are no corrections nearby
        if nlpData.correctionIds.length == 0
            return false

        # Otherwise, check to see if corrections are completed
        for corrId in nlpData.correctionIds
            [corrc0, corrc1, corrd0, corrd1] = doc.rangeOf(corrId)
            corrStart = [corrc0, corrd0]
            corrEnd   = [corrc1, corrd1]
            if doc.compare(corrStart, end) > 0 and doc.compare(start, corrEnd) > 0
                return false
            #else if corrc0 == corrc1
            # Cursor is not a range

        if _.isFunction(notify) then notify(translations.edit_close_to_correction)
        return true

    # Delete null corrections, to be called after mentor edits a correction
    removeCorrectionIfIrrelevant = (doc, corr, cursor) ->
        corrRange = doc.rangeOf corr
        if corrRange.length < 2 then return # Correction already absent
        corrPath  = doc.so.at('corrections').at(corr)

        # Don't delete corrections with multiple attempts already
        corrDoc = new StyledText(corrPath)
        attempt = doc.text(corrRange)
        if attempt == corrDoc.text()
            corrPath.nullify()
            doc.rmAnno(corr)
            cursorRange = corrDoc.rangeOf cursor
            if cursorRange.length >= 2
                doc.mvAnno(cursor, corrRange[0] + cursorRange[0], corrRange[0] + cursorRange[1])
            doc.commit()

    # Move the cursor by delta
    move = (doc, cursor, delta) ->
        image = doc.so.image()
        if !image? then return
        cursorRange  = doc.rangeOf cursor
        unless cursorRange[0]? then return

        annosHere = image.annosAt[cursorRange[0]]
        offset    = undefined

        # Check to see if there is a correction at this
        # index we can move back into
        if delta == -1
            cursorIndex = _.indexOf annosHere, cursor
            remaining   = _.take annosHere, cursorIndex
            while remaining.length > 0
                l         = _.last    remaining
                remaining = _.initial remaining
                if _.contains(remaining, l)
                    # Found new focus point
                    offset = remaining.length
                    break

        # Check to see if there is a correction at this
        # index we can move forward into
        if delta == +1
            cursorIndex = _.indexOf annosHere, cursor
            remaining   = _.drop annosHere, cursorIndex
            while remaining.length > 0
                l         = _.head remaining
                remaining = _.tail remaining
                if _.contains(remaining, l) and l != cursor
                    # The extra +2 is to overcome the fact that the cursor
                    # was just removed from the beginning
                    offset = remaining.length + 2
                    break

        # If we move into a correction, keep the same position
        # otherwise use doc.seek to find the new position
        newCursorPos =
            if offset? then cursorRange[0]
            else doc.seek(cursorRange[0], delta)[0]

        # By default, moving back goes to the end of corrections,
        # moving forward moves to the beginning of corrections
        offset ?=
            if delta < 0
                image.annosAt[newCursorPos].length
            else
                0
        doc.mvAnno(cursor, newCursorPos, newCursorPos, offset, offset).commit()


    # Add a cursor to the document or move it if it already exists
    focus = (doc, vs, cursor, start, end) ->
        if !doc.so.image()? then return
        l = doc.length()

        # Quick hack to fix version indiscrepancies
        # More principalled approach must wait rewrite of zync-text
        if start > l
            start = l
            console.warn('Rewrote cursor position to stay inside document')
        if end > l
            end = l
            console.warn('Rewrote cursor position to stay inside document')

        unless 0 <= start <= l and 0 <= end <= l
            throw new Error("Focus #{start}-#{end} in doc of length #{l}\n #{doc.toString()}")

        # Focus this doc, insert cursor
        # Check the cursor start position and add offset
        getOffset = (point) ->
            # If corrections start here, return the end of
            # the annotation array, else the beginning
            corrIds = _.filter( doc.annosAt(point), isCorrection )
            corrOffset = 0
            for corrId in corrIds
                [corrStart, corrEnd] = doc.rangeOf(corrId)
                if corrStart == point then corrOffset++

            # If there are sentence boundaries here, we should insert after the boundary.
            boundaryOffset = doc.unaryAnnos(point).length
            return corrOffset + boundaryOffset

        doc.mvAnno(cursor, start, end, getOffset(start), getOffset(end)).commit()

        # Let keyboard and other purescript functions know about focus change
        dispatchEvent 'zyncfocus', doc.so.pathId


    # Remove a cursor from the document
    blur = (doc, cursor) ->
        # Focus away from this doc, remove cursor
        doc.rmAnno(cursor).commit()

        # Let keyboard and other purescript functions know about focus change
        dispatchEvent 'zyncblur', doc.so.pathId


    # Insert text at the cursor
    insertAtCursor = (doc, cursor, text, notify) ->
        image = doc.so.image()
        if !image? then return
        [c0, c1, d0, d1] = doc.rangeOf cursor

        # Check for nearby corrections to disable editing
        unless c0? and c1? then return

        if editTooCloseToCorrection(doc, c0, c1, d0, d1, cursor, notify) then return

        doInsert = ->
            # Check for abbreviations / diacritic insertion
            replacementStart = undefined
            replacement =
                if text in ['\\', '/', '^', '$', '%']
                    replacementStart = doc.rangeOf(cursor)[0] - 1
                    if replacementStart >= 0
                        lastChar = doc.text().substring(c0 - 1, c0)
                        PS.keyboardShortcutLookup(lastChar + text)

            if replacement?
                doc.delete(replacementStart, replacementStart + 1).commit()
                text = replacement

            # Delete selected range and replace with text
            doc.insertAtCursor(text, cursor).commit()
            c0Prime = c0 - if replacement? then 1 else 0
            checkForEditedCorrections(doc, [c0Prime, c0Prime + text.length])

        if c1 - c0 > maxCharsToDeleteWithNoWarning && _.isFunction(areYouSure)
            nWords = doc.text([c0, c1]).split(' ').length
            areYouSure(translations.confirm_delete_much_text(words: nWords), doInsert)
        else
            doInsert()


    deleteAtCursor = (doc, cursor, delta, notify) ->
        [c0, c1, d0, d1] = doc.rangeOf cursor
        unless c0? and c1? then return
        if c0 == c1
            # No range selected, delete back or forward delta places
            [c1, d1] = doc.seek(c0, delta)
            if c0 == c1 then return # Nothing to delete
            if c1 < c0 or c1 == c0 and d1 < d0 then [c1, d1, c0, d0] = [c0, d0, c1, d1] # Get c0, c1 in the right order

        if editTooCloseToCorrection(doc, c0, c1, d0, d1, cursor, notify) then return

        doDelete = ->
            doc.delete(c0, c1).commit()
            checkForEditedCorrections(doc, [c0, c0])

        if c1 - c0 > maxCharsToDeleteWithNoWarning
            nWords = doc.text([c0, c1]).split(' ').length
            areYouSure(translations.confirm_delete_much_text(words: nWords), doDelete)
        else
            doDelete()


    requestHint = (doc, correctionId) ->
        # There is a correction under the cursor.  Use the current
        # text as an attempt
        doc.so.at('corrections', correctionId, 'dirty').update(true)
        doc.so.at('corrections', correctionId, 'hint').nullify()
        doc.so.commit()

    submitAtCursor = (doc, cursor) ->
        cursorRange = doc.rangeOf cursor
        unless cursorRange.length >= 2
            # The cursor isn't in this doc
            return
        correctionId =
            _.chain( doc.annosAt((cursorRange)...) )
                .filter( isCorrection )
            .first().value() # returns undefined if no corrections here
        if correctionId?
            # Try adding an attempt to the correction
            requestHint(doc, correctionId)
        else
            # No correction under cursor.  Insert a newline
            insertAtCursor(doc, cursor, '\n')

    expandCorrectionAtCursor = (doc, cursor, delta) ->
        [d0, d1] = doc.rangeOf cursor # Selection to delete
        isSelection = d0 != d1
        unless d0? and d1? then return

        # Delete delta characters left / right
        [d_] = doc.seek(d0, delta)

        # Reject very long corrections
        if d1 - d0 > 60
            notify(translations.essay_correction_too_long)
            return

        # Check for the beginning / end of the document
        if d_ == 0 or d_ == doc.length()
            # Check to see if seeking back goes too far back
            # This method detects the edge of the document
            # even if there are annotation bounds there
            [d_back] = doc.seek(d_, -delta)
            if (d_back - d0) * delta < 0 then return

        unless isSelection
            d0 = Math.min(d_, d0)
            d1 = Math.max(d_, d1)

        text = doc.text()
        [c0, c1] = [d0, d1] # d0, d1 will be the final range of the correction

        # Expand to nearest whitespace so that corrections are over words
        wordBoundary = /[\s.!¡¿?,:;]/
        while c0 > 0           and !wordBoundary.test(text[c0 - 1]) then c0 -= 1
        while c1 < text.length and !wordBoundary.test(text[c1])     then c1 += 1

        # Further expand to merge nearby corrections
        corrMergeStart     = Math.max(c0 - 2, 0)
        corrMergeEnd       = Math.min(c1 + 2, text.length)
        correctionsToMerge = _(doc.annosAt corrMergeStart, corrMergeEnd).filter isCorrection
        ranges = _(correctionsToMerge).map (x) -> doc.rangeOf(x)
        starts = _(ranges).map((r) => r[0]).concat [c0]
        ends   = _(ranges).map((r) => r[1]).concat [c1]
        c0     = _.min(starts)
        c1     = _.max(ends)

        # Find the content of the new correction
        corrImage = doc.so.at('corrections').image()
        accAnswer = (from, to) ->
            acc = ''
            for i in [from...to]
                # if this character doesn't fall inside a correction
                if _.all(ranges, (r) -> i < r[0] or i >= r[1])
                    # Add it to the correct answer
                    acc += text[i]

                # Save the contents of the old corrections
                for corrId in correctionsToMerge when doc.rangeOf(corrId)[0] == i
                    acc += corrImage[corrId].text
            return acc
        corrPrefix = accAnswer(c0, d0)
        corrSuffix = accAnswer(d1, c1)

        # Create new correction, or re-use existing one
        newCorrId =
            if correctionsToMerge.length > 0
                correctionsToMerge[0]
            else
                'correction' + generateId(existingIdMap = corrImage)

        # Remove the old corrections
        for corr in correctionsToMerge when corr != newCorrId
            # Delete the old correction
            doc.so.at('corrections', corr).nullify() #if corr != mergedCorrId
            doc.rmAnno corr

        attempt = doc.text([c0, c1])
        correction =
            annosAt: _.times(corrPrefix.length, (-> [])).concat( [[cursor, cursor]] ).concat( _.times(corrSuffix.length, (-> [])))
            text: corrPrefix + corrSuffix
            dirty: true
        doc.so.at('corrections', newCorrId).update(correction)
        doc.mvAnno(newCorrId, c0, c1)
        doc.rmAnno(cursor)
        doc.commit()

    # Enable cursor movement
    enableCursor = (registerEvent, doc, cursor, docId) ->

        unless _.isString(cursor)
            throw new Error "Illegal cursor"

        # Move the cursor
        # The movement is limited to the end of the doc
        registerEvent 'move', (event, delta) ->
            move(doc, cursor, delta)
            corrections = _.keys(doc.so.image().corrections)
            for corr in corrections
                corrDoc = new StyledText(doc.so.at('corrections').at(corr))
                r = corrDoc.rangeOf cursor
                if r.length < 2 then continue # performance shortcut

                cursorExitPoint =
                    if r[0] + delta < 0
                        Math.max(doc.rangeOf(corr)[0] + delta, 0)
                    else if r[1] + delta > corrDoc.text().length
                        Math.min(doc.rangeOf(corr)[1] + delta, doc.text().length)
                    else
                        undefined

                if cursorExitPoint?
                    # Teacher cursor moves out of a correction
                    corrDoc.rmAnno(cursor)
                    doc.mvAnno(cursor, cursorExitPoint, cursorExitPoint)
                    doc.commit()
                else
                    # Teacher cursor moves within a correction
                    move(corrDoc, cursor, delta)

        # Focus events are a command to focus this doc
        # insert the cursor at a position if it isn't already there
        registerEvent 'focus', (event, docUUIDToFocus, vs1, corr1, pos1, vs2, corr2, pos2) ->
            pos2  ?= pos1
            corr2 ?= corr1
            vs2   ?= vs1
            if !doc.so.image? or !doc.so.image()? then return
            if corr1 != corr2   then return

            [c0, c1] = doc.rangeOf cursor

            corrections = _.keys(doc.so.image().corrections)
            if docUUIDToFocus == docId
                vs =
                    if (vs1 != vs2)
                        # Usually caused by drag to select
                        # We should transpose pos1 here, but just do a quick hack for now
                        # as am considering replacing entire data model eventually
                        # There is a compensator inside focus() which catches boundary errors
                        vs2
                    else
                        vs1

                if !pos1?
                    # General focus demand, ignore if we already have focus
                    # otherwise, focus start of document
                    if c0? then return else pos1 = pos2 = 0

                _.defer ->
                    if corr1 == ''
                        # Focus main document
                        focus(doc, vs, cursor, pos1, pos2)
                    else
                        # Focus correction
                        blur(doc, cursor)
                        corrDoc = new StyledText(doc.so.at('corrections').at(corr1))
                        focus(corrDoc, vs, cursor, pos1, pos2)
            else
                # Unfocus document
                if c0? # Optimization: avoid work if we didn't have focus anyway
                    # TODO delete this?
                    psKeyboardCancel()
                    blur(doc, cursor)

            for corr in corrections when corr != corr1
                corrDoc = new StyledText(doc.so.at('corrections').at(corr))
                blur(corrDoc, cursor)

    # Enable response to insert, delete and submit events
    enableEdit = (registerEvent, doc, cursor, notify) ->

        so  = doc.so
        unless _.isObject(doc) and _.isString(cursor)
            throw new Error 'Illegal arguments'

        # Insert characters at the cursor (typing or paste event)
        registerEvent 'insert', (event, text) ->
            insertAtCursor(doc, cursor, text, notify)

        # Delete or backspace next to the cursor
        registerEvent 'delete', (event, delta) ->

            if !doc.so.image()? then return
            # If no delta is specified, delete back one character
            delta ?= -1
            deleteAtCursor(doc, cursor, delta, notify)

        registerEvent 'submit', (event) -> # Called when 'Enter' pressed
            if !doc.so.image()? then return
            if doc.rangeOf(cursor)[0]?
                psKeyboardCancel()
                if behaviour.onSubmit
                    # User-defined behaviour
                    blur(doc, cursor)
                    dispatchEvent 'zyncsubmit'
                else
                    # Either new line or correction submission
                    submitAtCursor(doc, cursor)

        registerEvent 'blur', (event) ->
            # External requests to blur all zync components
            if !doc.so.image()? then return
            if doc.rangeOf(cursor)[0]?
                psKeyboardCancel()
                blur(doc, cursor)



    enableCorrection = (registerEvent, doc, cursor, notify) ->

        registerEvent 'insert', (event, text) ->

            if !doc.so.image()? then return
            expandCorrectionAtCursor(doc, cursor, 0)

            corrections = _.keys(doc.so.image().corrections)
            for corr in corrections
                corrDoc = new StyledText(doc.so.at('corrections').at(corr))
                if corrDoc.rangeOf(cursor).length > 0
                    insertAtCursor(corrDoc, cursor, text)
                    removeCorrectionIfIrrelevant(doc, corr, cursor)

        registerEvent 'delete', (event, delta) ->
            if !doc.so.image()? then return
            corrections = _.keys(doc.so.image().corrections)
            correctionChanged = false
            for corr in corrections
                corrDoc = new StyledText(doc.so.at('corrections').at(corr))
                if corrDoc.rangeOf(cursor).length >= 2
                    correctionChanged = true
                    deleteAtCursor(corrDoc, cursor, delta)
                    if corrDoc.text().length == 0
                        # If we completely deleted the correct answer
                        # return the cursor to the main text
                        [r0, r1] = doc.rangeOf(corr)
                        corrDoc.rmAnno(cursor)
                        doc.mvAnno(cursor, r0, r0)
                        doc.commit()
                    removeCorrectionIfIrrelevant(doc, corr, cursor)

            if !correctionChanged
                expandCorrectionAtCursor(doc, cursor, delta)

    destroySpan = (span) ->
        if span.innerSpans?
            destroySpan(innerSpan) for innerSpan in span.innerSpans
        for fn in span.onDestroy ? []
            fn()


    # Rebuild entire essay html
    rebuild = notifyErrors ->
        # Check validity of so
        essay = so.image()

        unless essay? and essay.text? and essay.annosAt?
            # SO not yet initialized.  Wait for server to update it
            zyncTextEl.html("<span>#{translations.loading}...</span>")
            return

        doc = new StyledText(so, [ZyncTextCorrectionsPlugin, ZyncTextCorrectionsFns])

        role = getRole()
        for oldRole in ["author", "mentor"]
            zyncTextEl.removeClass("role-#{oldRole}")
        zyncTextEl.addClass("role-#{role}")

        enableCorrection(registerEvent, doc, cursor, notify) if role == 'mentor'
        enableEdit(registerEvent, doc, cursor, notify)       if role == 'author'
        docId = so.pathId + "|" + userId
        enableCursor(registerEvent, doc, cursor, docId)     if role == 'mentor' or role == 'author'


        for oldSpan in spans
            destroySpan(oldSpan)

        spans = ZyncTextRender.render(zyncTextEl, authors, mentors, doc, disableGrammarChecking, cursor, getLanguageHint)


    # Performs the required DOM updates for an op
    updateDOM = notifyErrors (op, authorCursors, mentorCursors) ->
        # Find the ranges of any updated corrections and include them
        # in HTML regeneration
        corrOp = op.at('corrections')
        annoOp = op.at('annosAt')
        textOp = op.at('text')
        correctionNLPData = doc.correctionNLPData()

        correctionRanges = []
        if corrOp?
            for corr in corrOp.keys()
                corrRange = doc.rangeOf corr
                correctionRanges.push(corrRange) if corrRange.length >= 2

        [nToKeep, deletedSpans, insertedSpans, dl] =
            if (annoOp? and annoOp.opType == 'Splice') or
                    (textOp? and textOp.opType == 'Splice') or
                    correctionRanges.length > 0
                # Splice of text and/or annos
                doc.spanUpdate(textOp, annoOp, spans, correctionRanges)
            else if annoOp? or textOp?
                # Regenerate entire essay
                [0, spans, doc.spans(), 0]
            else
                # Neither text or annos affected
                [spans.length, [], [], 0]

        prefix = _.take(spans, nToKeep)
        suffix = _.drop(spans, nToKeep + deletedSpans.length)
        span.start += dl for span in suffix
        spans  = prefix.concat insertedSpans, suffix
        deletedSpans.map destroySpan

        # Destroy divs for old spans
        spanIds = _.pluck(deletedSpans, 'id')
        spanNodes = zyncTextEl.children()
        for node in spanNodes
            if _.contains(spanIds, $(node).attr('id'))
                $(node).remove()

        spanNodes = zyncTextEl.children()

        # Update the offset property of the nodes
        # to ensure that clicks continue to work
        for n in [nToKeep ... spanNodes.length]
            node = $(spanNodes[n])
            if node[0].tagName.toLowerCase() == 'zync-text-messages'
                # Ignore notifications tag
                break
            focusData = node.attr('data-focus')
            [corr, currentOffsetString] = focusData.split '|'
            currentOffset = parseInt currentOffsetString
            newOffset = currentOffset + dl
            node.attr('data-focus', corr + '|' + newOffset)

        newHtml = []
        for span in insertedSpans
            newHtml.push ZyncTextRender.spanToHTML(span, doc, cursor,
                                    authorCursors, mentorCursors,
                                    correctionNLPData, disableGrammarChecking, getLanguageHint)

        if nToKeep > 0
            # There are remaining nodes at start
            # Find the correct node to insert after
            insertAfter = $(spanNodes[nToKeep - 1])
            for node in newHtml.reverse()
                insertAfter.after(node)
        else
            # There are no remaining nodes at start,
            # prepend all the new nodes
            for node in newHtml.reverse()
                zyncTextEl.prepend(node)

    # Update / rebuild when essay changes
    listenerId = so?.onChange (image, op) ->
        # Get the grammar check info for each sentence and correction
        # Need start -> [hash, [corr]] and corr -> [hash]

        # If role hasn't changed, just do a diff update
        newRole = getRole()
        if image.text.length < 500 or newRole != role or op.opType != 'JsonOp' or op.at('nlp')?
            # Rebuild on role change, if entire essay replaced, or new NLP results
            # In future, could only regenerate sections affected by NLP updates
            rebuild()
        else
            # Incrementally update the DOM (speed)
            authorCursors  = ZyncTextRender.idsToCursors(authors)
            mentorCursors = ZyncTextRender.idsToCursors(mentors)
            updateDOM(op, authorCursors, mentorCursors)

        # Update the version attribute on the root element
        zyncTextEl[0].setAttribute('data-vs', so.vs())


        # Notify Purescript keyboard of changes
        prevChar = ' '
        if newRole == 'author'
            [c0, c1] = doc.rangeOf cursor
            if c0 > 0
                prevChar = doc.text [c0 - 1, c0]
        if newRole == 'mentor'
            # Check corrections for cursor
            for corrId, corrObj of image.corrections when !co?
                corrDoc = new ZText(corrObj)
                [c0, c1] = corrDoc.rangeOf cursor
                if c0 > 0
                    prevChar = corrDoc.text [c0 - 1, c0]
        psKeyboardSend(prevChar, image.lang ? langCode ? '')


        # If role changes, rebuild entire essay
        # On essay setup role change from undefined to a valid value,
        # causing rebuild to be called here
        role = newRole

        # Let any listeners know the new value when text changes
        if behaviour.onTextChange and op.at('text')?
            dispatchEvent 'textchange', image.text

        # Scroll to the author's cursor when it gets close to the bottom
        doScroll = ->
            cursorEls = document.getElementsByClassName('cursor' + userId)
            if cursorEls.length > 0
                cursorEl = cursorEls[0]

                # Check to see if our keyboard is present
                keyboard = document.querySelector('.touch.keyboard')
                keyboardOffset =
                    if keyboard?
                        keyboard.getBoundingClientRect().height
                    else
                        0

                {left, top, width, height} = cursorEl.getBoundingClientRect()
                # See http://stackoverflow.com/questions/1248081/get-the-browser-viewport-dimensions-with-javascript
                currentScroll = window.scrollY || window.pageYOffset
                vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
                margin = 0.15 * vh
                maxHeight = vh - keyboardOffset - margin - height
                if top > maxHeight
                    window.scrollTo(0, top + currentScroll - maxHeight)
                if top < margin
                    window.scrollTo(0, top + currentScroll - margin)
        _.delay doScroll, 50 # Keyboard is debounced by 20ms


    # Do first build
    if so.isLoaded() then rebuild()

    # Listen to focus and blur events
    zyncTextEl[0].addEventListener 'focus', (event) ->
        broadcast('focus', uuid, so.vs(), '')


    # Return cleanup function
    onSubmit:     (value) -> behaviour.onSubmit = value
    onTextChange: (value) -> behaviour.onTextChange = value
    focusMe: -> broadcast('focus', uuid, so.vs(), '')
    personnelChange: (a, m) ->
      authors = a
      mentors = m
      rebuild()
    destroy: ->
        so.unsubscribe(listenerId)
        globalUnregister(uuid)


# TODO remove global variables
window.initializeKeyListeners = (keystrokesOut) -> (userId) ->

    touches = {} # Map of id -> [startEl, offset]

    # Cross-browser caretRangeFromPoint
    # Returns node at point and text offset within element
    getRangeFromPoint = (x, y) -> # x and y are client coordinates relative to viewport
        NOT_FOUND = [undefined, undefined]
        if (document.elementFromPoint) # Older browsers
            target = document.elementFromPoint(x, y)
            r = document.createRange()

            ordering = (rect, rx, ry) ->
                if ry > rect.bottom
                    +1
                else if  y < rect.top
                    -1
                else if x > rect.right
                    +1
                else if x < rect.left
                    -1
                else
                    0

            getBounds = (target) ->
                if target.nodeType == Node.TEXT_NODE
                    # Text node selected
                    r.setStart(target, 0)
                    r.setEnd(target, target.textContent.length)
                    r.getBoundingClientRect()
                else if _.isFunction(target.getBoundingClientRect)
                    # Bounds of node
                    target.getBoundingClientRect()
                else
                    # Non-displayed node selected somehow
                    {left: 0, top: 0, width: 0, height: 0}

            getFromTextNode = (target) ->
                l = target.textContent.length
                n = 0
                while n < l
                    r.setStart(target, n)
                    r.setEnd(target, n + 1)
                    rect = r.getBoundingClientRect() # Relative to viewport?
                    switch ordering(rect, x, y)
                        when -1
                            return [target, Math.max(n - 1, 0)]
                        when  0
                            {left, top, width, height} = rect
                            if x - left < width / 2
                                # Place cursor to the left
                                return [target, n]
                            else if x - left <= width
                                # Place cursor to the right
                                return [target, n + 1]
                    n++

                return [target, l]

            slice = (target, start, end) -> Array.prototype.slice.call(target, start, end)

            findTextNode = (nodeList) ->
                if nodeList.length == 1
                    node = nodeList[0]
                    if node.nodeType == Node.TEXT_NODE
                        # We found the text node
                        getFromTextNode(node)
                    else if node.childNodes.length == 0
                        # Empty node, usually startOf annotation
                        [node, 0]
                    else
                        # Search the child nodes
                        findTextNode(node.childNodes)
                else
                    # nodeList.length >= 2, do binary search step
                    m = Math.floor(nodeList.length / 2)
                    mNode = nodeList[m]
                    switch ordering(getBounds(mNode), x, y)
                        when -1 then findTextNode slice(nodeList, 0, m)
                        when  0 then findTextNode [mNode]
                        when  1 then findTextNode slice(nodeList, m)

            if target != null
                return findTextNode([target])

        # Default case
        NOT_FOUND


    getFocusData = (el) ->
        # Filter out odd browsers selecting a null element
        if !el? then return undefined

        # Move up the the nearest non-text element
        if !el.getAttribute? then el = el.parentNode

        # Find the uuid and version of this element, if any
        essayRootEl = el
        uuid = essayRootEl.getAttribute('data-uuid')
        while !uuid and essayRootEl.parentNode? and essayRootEl.parentNode.getAttribute?
            essayRootEl = essayRootEl.parentNode
            uuid = essayRootEl.getAttribute('data-uuid')
        vs = parseInt(essayRootEl.getAttribute('data-vs'))

        # Unless we are in a zync text document, just ignore this event
        return undefined unless uuid

        # Focus the end of the essay when the essay node is clicked directly
        # Get offset data embedded in the elements if any
        # Default is for click event directly on the zync-text element
        focusData = el.getAttribute('data-focus') ? "|0"
        [uuid, vs, focusData]


    focusRange = (uuidAndFocusData1, offset1, uuidAndFocusData2 = uuidAndFocusData1, offset2) ->
        unless uuidAndFocusData1? then return
        [uuid, vs1, focusData1] = uuidAndFocusData1
        unless uuid? and vs1? and focusData1? and offset1? then return
        offset2 ?= offset1
        [uuid2, vs2, focusData2] = uuidAndFocusData2
        vs2 ?= vs1
        focusData2  ?= focusData1
        if isNaN(offset1) || isNaN(offset2) then return false

        [corr1, base1] = focusData1.split '|'
        [corr2, base2] = focusData2.split '|'
        pos1 = parseInt(base1) + offset1
        pos2 = parseInt(base2) + offset2

        broadcast 'focus', uuid, vs1, corr1, pos1, vs2, corr2, pos2
        return true


    # Find the point delta lines below the cursor and focus it
    focusLine = (delta) ->
        lineDelta = 27 * delta
        unless userId? then return
        cursorEl = document.getElementsByClassName('cursor' + userId)[0]
        unless cursorEl? then return
        rect = cursorEl.getBoundingClientRect()
        tx = (rect.x ? rect.left) + rect.width / 2.0
        ty = (rect.y ? rect.top) + rect.height / 2.0 + lineDelta
        [target, offset] = getRangeFromPoint(tx, ty)
        if target? and offset?
            focusData = getFocusData(target)
            focusRange(focusData, offset)


    selectionStart = (e, touchId, clientX, clientY) ->
        # Get info about the selection
        [target, offset] = getRangeFromPoint(clientX, clientY)
        if target? and offset?
            focusData = getFocusData(target)
            if focusData?
                e.stopPropagation() # Prevent blur-on-click behaviour
                e.preventDefault()  # Prevent bubbling
            touches[touchId] = [focusData, offset]
            refocused = focusRange(focusData, offset)
            if refocused
                # Clear the browser selection if we really did a focus
                sel = window.getSelection()
                if      _.isFunction(sel.empty)           then sel.empty()
                else if _.isFunction(sel.removeAllRanges) then sel.removeAllRanges()

    selectionChange = (touchId, clientX, clientY) ->
        [target, offset] = getRangeFromPoint(clientX, clientY)
        if target? and offset?
            [focusData1, startOffset] = touches[touchId]
            focusData2 = getFocusData(target)
            if focusData2? then focusRange(focusData1, startOffset, focusData2, offset)

    selectionEnd = (touchId) ->
        delete touches[touchId]

    document.onmousedown = (e) -> selectionStart(e, 'mouse', e.clientX, e.clientY)
    document.onmousemove = (e) -> if touches.mouse then selectionChange('mouse', e.clientX, e.clientY)
    document.onmouseup   = (e) -> selectionEnd('mouse')
    document.onmouseleave = (e) -> selectionEnd('mouse')

    touchstart = (e) ->
        for touch in e.changedTouches
            selectionStart(e, touch.identifier, touch.clientX, touch.clientY)
    #touchmove = (e) -> # These don't work!
        #for touch in e.changedTouches
            #selectionChange(touch.identifier, touch.clientX, touch.clientY)
    #touchend = (e) ->
        #for touch in e.changedTouches
            #selectionEnd(touch.identifier)
    document.addEventListener('touchstart', touchstart, false)
    #document.addEventListener('touchmove', touchmove, false)
    #document.addEventListener('touchend', touchend, false)
    #document.addEventListener('touchcancel', touchend, false)



    # Check to see if the cursor is in an input
    isNativeInput = ->
        # Activeelement can be null in IE
        el = document.activeElement
        elType = el?.tagName.toLowerCase()
        isInput = elType == 'input' and el.type.toLowerCase() not in ['button', 'submit']
        isTextArea = elType == 'textarea'
        isFlash = elType == 'object'
        isInput || isFlash || isTextArea
    getKeyCode    = (e) -> e.which ? (e.charCode ? e.keyCode) # Copy paste of jQuery's method

    # Pick up as many keypresses as possible via onkeypress
    document.onkeypress = (e) ->
        return if isNativeInput()
        key = getKeyCode(e)

        if !e.altKey and !e.ctrlKey
            key = e.which
            char = String.fromCharCode(key)

            # Let zync-text components know about the keypress
            broadcast('insert', char)

            e.preventDefault()

    # Pick up key presses not available through onkeypress via onkeydown
    document.onkeydown   = (e) ->
        return if isNativeInput()
        key = getKeyCode(e)
        # Ignore when an input is focused
        swallow = true
        switch
            when key == KeyEvent.DOM_VK_BACK_SPACE
                broadcast('delete', -1)
            when key == KeyEvent.DOM_VK_DELETE
                broadcast('delete', +1)
            when key == KeyEvent.DOM_VK_RIGHT
                broadcast('move', +1)
            when key == KeyEvent.DOM_VK_LEFT
                broadcast('move', -1)
            when key == KeyEvent.DOM_VK_UP
                focusLine(-1)
            when key == KeyEvent.DOM_VK_DOWN
                focusLine(+1)
            when key == KeyEvent.DOM_VK_RETURN || key == KeyEvent.DOM_VK_ENTER
                broadcast('submit')
            else
                swallow = false
        if swallow
          e.preventDefault()

    # Get virtual keypresses and turn them into events
    keystrokesOut.subscribe (keycode) ->
        if keycode == 37 # Left arrow
            broadcast('move', -1)
        else if keycode == 39 # Right arrow
            broadcast('move', +1)
        else if keycode == 13 # Enter keycode
            broadcast('submit')
        else if keycode == 8 # Delete keycode
            broadcast('delete', -1)
        else
            if keycode > 0
              char = String.fromCharCode(keycode)
              if char.match /[a-zA-Z0-9,.!’:;-? %/^$\\]/
                broadcast('insert', char)
            else
              keycode = -keycode
              char = String.fromCharCode(keycode)
              broadcast('delete', -1)
              broadcast('insert', char)
