# DEPRECATED, please use ZText instead
#
# Represents a styled document, which contains text and a number
# of annotations which can be overlapping (unlike html). Cursors, corrections, and so on
# can all be represented using annotations, but these must be implemented at a higher level
# of abstraction.
#
# Create the document by supplying an initial text string, eg:
#   doc = new Doc('initial string')
#
# Add edits to the document using the remaining methods, and call apply()
# to apply the edit, eg:
#   doc.insert('abc', 3)
#      .addAnno('bold', 0, 4)
#      .commit()
#
# In order to find the correct indices at which to make operations, the convenience
# methods annosAt(), rangeOf() and seek() can be used.  Any indices returned by these
# methods become invalid after calling apply() and updating the document
#
# Properties:
#   length: the length of the document, including a length of 1 for every start and end
#           of annotation. Eg: x<b>y</b>z has length 5.  Insert at length to append to the
#           document
#   spans: the document as a list of uniform spans with annotations.  The spans have the
#           following properties:
#              text:        the text content of the span
#              annotations: a list, containing the ids of annotations which contain the span
#              start:       start index
#              startOf:     an optional annotation id flagging the start of that annotation
#              sentences:   a list of hash codes representing the sentences which overlap
#                           this span
#   version: the version number of the document.  Increases monotonically on each call to
#            apply()
class StyledText

    SENTENCE_BOUND = 's'
    UNARY_ANNOS = [SENTENCE_BOUND]

    # Calculates the range affected by an operation,
    # which may contain annotation and text operations
    rangeAffected = (textOp, annoOp) ->
        # Args undefined if there are no ops on annos or text
        # Calculate the range affected by the op (before op is applied)
        if annoOp? and annoOp.opType == 'Splice'
            [a0, a1] = annoOp.updatedRange()
            a1 -= 1 # Because annos are 1 longer than text
            if textOp?
                [t0, t1] = textOp.updatedRange()
                [Math.min(t0, a0), Math.max(t1, a1)]
            else
                [a0, a1]
        else if textOp? and textOp.opType == 'Splice'
            # A text replace operation that doesn't affect annos
            textOp.updatedRange()
        else if annoOp? or textOp?
            # Must be a replace operation on anno or text, entire range is affected
            [0, Number.MAX_VALUE]
        else
            # Neither op defined, no change
            []

    # Takes a list of spans and a range.
    # Expands the range to the boundary of the spans
    rangeOfSpansToReplace = (oldSpans, start, end) ->

        ## Switch index scan to binary search?
        ## Scan the old spans for the start and end
        # Step 1: find the range of affected spans
        startIndex  = undefined
        endIndex    = undefined
        for span in oldSpans
            if span.start <= end and span.start + span.text.length >= start
                # startIndex set to start of first span that overlaps edited range
                startIndex ?= span.start
            else if span.start > end
                # endIndex set to beginning of first span that clears the edited range
                endIndex ?= span.start
                break

        if !endIndex?
            # Make sure endIndex is set to the end of the document if not yet set
            if oldSpans.length > 0
                lastSpan = oldSpans[oldSpans.length - 1]
                endIndex = lastSpan.start + lastSpan.text.length
            else
                endIndex = 0

        [startIndex, endIndex]


    rangeOf: (annoId) ->
        new ZText(@so.image()).rangeOf(annoId)

    spans: (annos = [], startIndex = 0, endIndex = undefined, processStartAnnos = true, processEndAnnos = true)->

        zText = new ZText(@so.image())
        zText.spans(annos, startIndex, endIndex, processStartAnnos, processEndAnnos)

    spanUpdate: (textOp, annoOp, oldSpans, additionalRanges = []) ->

        docLength = @length()
        dl = if annoOp? and annoOp.opType == 'Splice' then annoOp.dl() else 0

        # Range affected by ops on annosAt or text
        [opStart, opEnd] = rangeAffected(textOp, annoOp)

        # Expand range to include any overlapping annotations
        if opEnd == Number.MAX_VALUE
            [opStart, opEnd] = [0, docLength]
        if opStart? and opEnd? and opEnd != Number.MAX_VALUE
            # This should use the image from before the op, but
            # using the after-op image and checking that the 2nd argument
            # to annosAt is inbounds is sufficient
            overlappingAnnos = @annosAt(opStart, Math.min(opEnd, docLength))
            for overlappingAnno in overlappingAnnos
                additionalRanges.push @rangeOf(overlappingAnno)

        # Incorporate any additional ranges mandated by parameters
        for [rStart, rEnd] in additionalRanges
            if opStart? and opEnd?
                opStart = Math.min(opStart, rStart)
                opEnd   = Math.max(opEnd, rEnd)
            else
                [opStart, opEnd] = [rStart, rEnd]

        unless opStart? and opEnd?
            # Op doesn't affect spans
            return [0, [], [], 0]

        # Expand range to surrounding sentence by assuming the same sentences exist in
        # both versions
        opEndAfter = opEnd + dl
        [opStart, xxx]    = @sentenceAround(Math.min(opStart, docLength))
        [xxx, opEndAfter] = @sentenceAround(Math.min(opEndAfter, docLength))
        opEnd = Math.max(opEnd, opEndAfter - dl)

        [startIndex, endIndex] = rangeOfSpansToReplace(oldSpans, opStart, opEnd)

        processStartAnnos = true
        processEndAnnos   = endIndex == opEnd

        # Step 2: find the affected spans and queue them for deletion
        # This includes 0-length spans before affected spans
        spansForDeletion = []
        nToKeep          = 0
        annos            = []
        for span in oldSpans
            spanend = span.start + span.text.length
            if span.start < startIndex
                nToKeep++
                annos =
                    if _.isArray(span.innerSpans) and span.innerSpans.length > 0
                        span.innerSpans[span.innerSpans.length - 1].annotations
                    else
                        span.annotations
            else if startIndex <= span.start < endIndex or (processEndAnnos and spanend == endIndex)
                spansForDeletion.push span

        # If last deleted span is grouped, it will contain all 0-length
        # annotations at the end of the group, so we must
        # regenerate them
        lastSpanIsGrouped = spansForDeletion.length > 0 and
            spansForDeletion[spansForDeletion.length - 1].innerSpans?

        processEndAnnos |= lastSpanIsGrouped

        newSpans = @spans(annos, startIndex, endIndex + dl, true, processEndAnnos)
        pspan = (spans) -> JSON.stringify _.map(spans, (s) -> {text: s.text, annosAt: s.annosAt})
        [nToKeep, spansForDeletion, newSpans, dl]

    constructor: (arg, plugins = []) ->
        if arg? and _.isFunction(arg.image)
            # arg is a Zync object
            @so = arg
            image = @so.image()
            # check arguments
            new ZText(image)
        else
            throw new Error 'Argument to StyledText must be Zync or String'

        # Setup plugins
        for plugin in plugins
            _.extend(@, plugin)

    getImage: ->
        @so.image()

    length: ->
        @so.image()?.text?.length ? 0

    # Applies all unapplied edits, and recalculates all static values
    # This is the only method which changes the document
    commit: ->
        @so.commit()
        return this

    unaryAnnos: (point) ->
        _.filter(@so.image().annosAt[point], (a) -> _.contains(UNARY_ANNOS, a))


    compare: ([i0, o0], [i1, o1]) ->
        if      i1 > i0 then 1
        else if i1 < i0 then -1
        else if o1 > o0 then 1
        else if o1 < o0 then -1
        else 0

    # ---- Convenience methods -------------

    # Seek n characters to the right (to the left if n < 0), clipping at boundaries
    # seek(0, 1) --> 1
    # seek(1, 1) --> 2
    # seek(2, 1) --> 3
    # seek(3, 1) --> 4
    # seek(4, 1) --> 5
    # seek(5, 1) --> 5
    seek: (from, n) ->
        annosAt = @so.image().annosAt
        endOf = (i) ->
            [i, annosAt[i].length]
        startOf = (i) ->
            [i, 0]
        if from + n < 0
            startOf(0)
        else if from + n >= annosAt.length
            endOf(annosAt.length - 1)
        else
            if n < 0
                endOf(from + n)
            else
                startOf(from + n)

    # Returns the annotations at a given index

    # Returns annotations which span the given position or range
    # Eg: for document x<a></a><b>y</b>
    #   annosAt 0 --> []
    #   annosAt 1 --> ['a', 'b']
    #   annosAt 2 --> ['b']
    annosAt: (from, to = from) ->
        new ZText(@so.image()).annosAt(from, to)

    checkIndex: (index) ->
        new ZText(@so.image()).checkIndex(index)

    # Check that an argument is a string
    checkIsString: (string, minLength = 1) -> unless _.isString(string) and string.length >= minLength
            throw new Error "#{string} should be a string of length #{minLength} or more"


    # ------ Editing methods -----

    # Insert text at the given index
    insert: (text, index, annosToInsert = undefined, offset = 0) ->
        @checkIndex(index); @checkIsString(text, minLength = 1)
        {annosAt} = @so.image()
        annos = annosAt[index]
        if offset > annos.length
            throw new Error "#{offset} is out of range in anno list #{JSON.stringify annos}"

        movedAnnos = _.take(annos, offset)
        nKeptAnnos = annos.length - offset
        annosToInsert ?= [movedAnnos].concat _.times(text.length - 1, -> []) # Blank annotations if not given
        @so.at('text').insert(index, text)
        if annosToInsert.length > 0
            @so.at('annosAt').insert(index, annosToInsert)
        if offset >  0
            @so.at('annosAt', index).delete(0, offset)
        return this


    # Delete from the first index to the second
    # Since all annotations are kept, the offsets at the indices don't matter
    delete: (from, to) ->
        if from > to
            [to, from] = [from, to]
        n = to - from
        unless n > 0 then throw new Error 'Delete must be at least 1 character'
        @checkIndex(from); @checkIndex(to)

        # Get annos in delete zone
        annos = []
        for i in [from..to]
            annos = annos.concat(@so.image().annosAt[i])

        @so.at('text').splice(from, n, '')
        @so.at('annosAt').splice(from, n + 1, [annos])
        return this


    mvAnno: (id, start, end = start, startOffset, endOffset) ->
        @rmAnno(id)
        @addAnno(id, start, end, startOffset, endOffset)


    # Add an annotation from the start index to the end
    addAnno: (id, start, end, startOffset, endOffset) ->
        @checkIndex(start); @checkIndex(end); @checkIsString(id)

        # Check for unary annos, start after them, and end before them by default
        {annosAt} = @so.image()
        startOffset ?= _.lastIndexOf(annosAt[start], SENTENCE_BOUND) + 1
        endOffset   ?= 0

        @so.at('annosAt', start).insert(startOffset, [id])
        @so.at('annosAt', end).insert(endOffset, [id])
        return this

    addUnaryAnno: (id, pos, posOffset = 0) ->
        @checkIndex(pos)
        @checkIsString(id)
        @so.at('annosAt', pos).insert(posOffset, [id])
        return this

    # Delete an annotation.  If the annotation does not exist will
    # fail silently
    rmAnno: (id) ->
        @checkIsString(id)
        op = {}
        @so.image().annosAt.forEach (annoIds, index) =>
            if annoIds? then for annoId, priority in annoIds
                if annoId==id
                    @so.at('annosAt', index).delete(priority, 1)
        return this

    insertAtCursor: (text, cursor) ->
        [c0, c1, d0, d1] = @rangeOf cursor

        # Filter illegal characters from the text
        cleanText = ''
        for n in [0...text.length]
            ch = text[n]
            # Normalize apostrophes
            if "`’'′´`".indexOf(ch) >= 0 then ch = '’'
            unless ch.toUpperCase() == ch and ch.toLowerCase() == ch and "ßẞ\"’?!¿¡.,;:-£$€¥|() \n0123456789".indexOf(ch) < 0
                cleanText += ch

        # Ignore if cursor not present
        if !c0? then return this

        # Ignore repeated spaces next to spaces and at line start
        if text == ' '
            if c0 == 0 or ' \n'.indexOf( @text([c0 - 1, c0]) ) >= 0
                return this

        # Delete selected text
        if c0 < c1 then @delete(c0, c1)

        # Insert the new text
        if cleanText.length > 0
            @insert(cleanText, c0, undefined, d0)
        return this


    # Extract plain text from the doc
    text: (range) ->
        new ZText(@so.image()).text(range)


    setText: (text) ->
        @so.at('text').update(text)
        @so.at('annosAt').update(_.times(text.length + 1, -> []))
        return this


    sentences: ->
        image = @so.image()
        lastI = 0
        for annos, i in image.annosAt when _.contains(annos, SENTENCE_BOUND)
            sentence = image.text.slice(lastI, i)
            res = [i, sentence]
            lastI = i
            res

    sentenceAround: (point) ->
        new ZText(@so.image()).sentenceAround(point)

    expandRange: ([start, end, startOffset, endOffset], n = 0) ->
        if n > 0 then throw new Error 'Unimplemented'
        [start, end, 0, @so.image().annosAt[end].length]

    # For debugging purposes
    toString: -> JSON.stringify @so

    getAnno: (annoId) ->
        new ZText(@so.image()).getAnno(annoId)


# We are gradually extracting StyledText into ZText, in order to remove the reference
# to @so
class ZText

    SENTENCE_BOUND = 's'
    UNARY_ANNOS = [SENTENCE_BOUND]

    spanIdGen = 0
    newSpanId = -> "span" + (spanIdGen++)

    constructor: (@image) ->
        # Add corrections functions from ls
        _.extend(@, ZyncTextCorrectionsFns)
        unless @image.annosAt? and @image.text?
            throw new Error "Attempt to construct StyledText with uninitialized shared object"


    clone = (obj) ->
        if !obj?
            throw new Error 'Cannot clone undefined'
        else
            JSON.parse(JSON.stringify obj)

    # Generates document spans from startIndex to endIndex using the annotation index and text
    # Without arguments, generates spans for the entire document
    # Passing arguments:
    # @param {Array} annos - the annotations in play at the start of span generation
    # @param {Number} startIndex
    # @param {Number} endIndex
    # @param {Boolean} processStartAnnos - whether to process spans which begin and end at startIndex
    # @param {Boolean} processEndAnnos   - whether to process spans which begin and end at endIndex
    generateSpans = (text, annosAt, annos, startIndex, endIndex, processStartAnnos, processEndAnnos) ->
        output       = [] # Result built up here
        oldIndex     = startIndex # the last index processed
        oldAnnosHere = [] # Annotations at the last index processed

        # Update the running active annotation list (annos)
        # and add annotation start spans
        trackAnno = (createSpans, anno) =>
            if _.contains(annos, anno)
                annos = _.without(annos, anno)
            else
                isUnary = _.contains(UNARY_ANNOS, anno)
                if createSpans then output.push(
                    id: newSpanId()
                    start: oldIndex
                    text:  ''
                    annotations: []
                    startOf: anno
                )
                unless isUnary then annos = annos.concat(anno)

        annosAt.concat([]).slice(startIndex, endIndex + 1).forEach (annosHere, sliceIndex) =>

            # Skip indices which are not dividers between spans
            unless annosHere.length > 0 or startIndex + sliceIndex in [0, endIndex]
                return

            for anno in oldAnnosHere
                trackAnno(createSpans = oldIndex > startIndex or processStartAnnos, anno)

            # For each divider after the 1st,
            # update the anno list and add a span
            #
            if sliceIndex > 0 then output.push
                id: newSpanId()
                start: oldIndex
                text: text.substring(oldIndex, startIndex + sliceIndex)
                annotations: clone(annos)

            # Prepare for the next divider
            oldAnnosHere = annosHere
            oldIndex     = startIndex + sliceIndex

            # Deal with the last span
            if sliceIndex == endIndex - startIndex and processEndAnnos
                annosHere.forEach (anno) ->
                    trackAnno(createSpans = true, anno)
        return output


    # Takes a list of spans, and groups together multiple spans
    # that should not have line breaks between them, for example
    # if a cursor appears in the middle of a word, this function
    # will create a span which contains the whole word and the cursor
    # @param {Array[span]} spanList
    # @return {Array[span]}
    groupNonBreakingSpans = (spanList) ->

        # Test to see if a character should allow a line break
        isBreaking = (char) -> char == ' ' or char == '\n'
        hasNoWhiteSpace = (text) ->
            text.indexOf(' ') < 0 and text.indexOf('\n') < 0

        splitSpan = (span, n) ->
            span1 =
                id: newSpanId()
                start: span.start
                text: span.text.substring(0, n)
                annotations: span.annotations
                onDestroy: span.onDestroy
            span2 =
                id: newSpanId()
                start: span.start + n
                text: span.text.substring(n)
                annotations: span.annotations
            [span1, span2]

        newSpans = []

        # Eat up the spanList with shift() and push results to newSpans
        while spanList.length > 0
            thisSpan = spanList.shift()
            thisText = thisSpan.text

            # Detect potential line break in upcoming text
            isBreakComingUp = (n = 0) ->
                if n >= spanList.length
                    true
                else if spanList[n].text.length > 0
                    isBreaking(spanList[n].text.substring(0, 1))
                else
                    isBreakComingUp(n + 1)

            startBreakHere =
                spanList.length > 0 and thisText.length > 0 and
                !isBreaking(thisText.substring(thisText.length - 1)) and # no break before this span
                !isBreakComingUp() # no break after this span

            if !startBreakHere
                # Just carry on
                newSpans.push thisSpan
                continue

            # A non-breaking span is necessary
            # 1. Break up this span:
            # 1a. Find the last whitespace in this span
            c = thisText.length
            while !isBreaking(thisText.substring(c - 1, c)) and c > 0
                c--

            # 2. Split on the whitespace and put the two halves in the right places
            [span1, span2] = splitSpan(thisSpan, c)
            if span1.text.length > 0
                newSpans.push span1
            noBreakSpan =
                id: newSpanId()
                start: span2.start
                text: span2.text
                annotations: thisSpan.annotations
                innerSpans: [span2]

            # 3. Add spans to the non-breaking span until we reach whitespace
            while spanList.length > 0 and hasNoWhiteSpace(spanList[0].text)
                thisSpan = spanList.shift()
                noBreakSpan.innerSpans.push thisSpan
                noBreakSpan.text += thisSpan.text

            # 4. Deal with the case that the end of the document is reached before whitespace
            if spanList.length == 0
                newSpans.push noBreakSpan
                break

            # 5. Break up the last span
            thisSpan = spanList.shift()

            # 5a. Find first whitespace in next span (whitespace is guaranteed)
            c = 0
            while !isBreaking(thisSpan.text.substring(c, c + 1)) and c < thisSpan.text.length
                c++

            # 5b. Split on the whitespace and put the halves in the right places``
            [span1, span2] = splitSpan(thisSpan, c)
            if span1.text.length > 0
                noBreakSpan.innerSpans.push span1
                noBreakSpan.text += span1.text
            if span2.text.length > 0
                spanList.unshift span2

            # 6. Push the non-breaking span
            newSpans.push noBreakSpan

        return newSpans

    spans: (annos = [], startIndex = 0, endIndex = undefined, processStartAnnos = true, processEndAnnos = true) ->
        groupNonBreakingSpans(@spansUngrouped(annos, startIndex, endIndex, processStartAnnos, processEndAnnos))

    spansUngrouped: (annos = [], startIndex = 0, endIndex = undefined, processStartAnnos = true, processEndAnnos = true) ->
        # Boundary check and default values
        image = @image
        unless image? and _.isString(image.text) and _.isArray(image.annosAt)
            return []
        endIndex    ?= image.text.length

        # Call pure functions to do most of the work
        generateSpans(image.text, image.annosAt, annos, startIndex, endIndex, processStartAnnos, processEndAnnos)


    # Check that a given index is valid for this document
    # An index can run from 0 to the length of the document
    # To insert at the end, index == @length
    checkIndex: (index) ->
        unless _.isNumber(index) and 0 <= index <= @length()
            throw new Error "Illegal index #{index} in document of length #{@length()}"

    text: (range) ->
        unless @image?.text? then return ''
        text = @image.text
        if !range? then return text
        if _.isArray(range) and range.length >= 2
            [from, to] = range
            text.substring(from, to)
        else
            throw new Error "Illegal range '#{range}'"

    length: ->
        @image?.text?.length ? 0

    annosAt: (from, to = from) ->
        @checkIndex(from); @checkIndex(to)
        image = @image
        unless image.annosAt? then throw
            new Error "annos not defined on shared object"
        annos = []
        toggleAnno = (anno) ->
            if _.contains(annos, anno) or _.contains(UNARY_ANNOS, anno)
                annos = _.without(annos, anno)
            else
                annos.push anno

        for annosHere, index in image.annosAt
            if index < from
                for anno in annosHere
                    toggleAnno(anno)
            else if index <= to
                for anno in annosHere
                    if !_.contains(annos, anno) and !_.contains(UNARY_ANNOS, anno)
                        annos.push anno
            else
                break

        return annos

    getAnno: (annoId) ->
        image = @image
        if (annoId.indexOf('correction') == 0) and image.corrections?
            image.corrections[annoId]
        else if image.annos?
            image.annos[annoId]
        else
            undefined

    # Returns the start and end of an annotation as an array,
    # Eg: for document xy<b>z</b>
    #   rangeOf 'b' --> [2, 4]
    # Also returns the offset within the AnnosAt array, eg:
    # for the textless document <b><c><c><b>
    # rangeOf 'c' --> [0, 0, 1, 2]
    rangeOf: (annoId) ->
        range = []
        offsets = []
        @image.annosAt.forEach (annos, index) -> if annos?
            for anno, offset in annos when anno == annoId
                range.push(index)
                offsets.push(offset)
        range.concat(offsets)

    getImage: -> @image

    gaps: () ->
        _.keys(@image.gaps)

    sentenceAround: (point) ->
        point = Math.min(@length(), point)
        {annosAt} = @image
        start = point; end = point
        while start > 0 and !_.contains(annosAt[start], SENTENCE_BOUND)
            start--
        while end < annosAt.length - 1 and !_.contains(annosAt[end], SENTENCE_BOUND)
            end++
        [start, end]

    stats: ->
        image = @image
        doc = @
        nWords = doc.text().trim().split(/\s+/).length
        language =
            if _.isString(image.lang)
                translations['lang_'+image.lang.replace("-","_")]
            else
                ''

        nTotal = image.nTotal
        nErrors = 0
        spellingErrs = []
        accentErrs = []
        capitalizationErrs = []
        punctuationErrs = 0
        grammarErrs = 0
        otherErrs = 0
        for hash, value of image.nlp when _.isObject(value)
            nErrors += 1
            if value.err
                # TODO: more systematic categorization of errors
                if value.id.indexOf("MORFOLOGIK") >= 0 or value.id.indexOf("HUNSPELL") >= 0
                    spellingErrs.push value.err
                else if value.id.indexOf("ACCENT") >= 0
                    accentErrs.push value.err
                else if value.id.indexOf("CAPITAL") >= 0
                    capitalizationErrs.push value.err
                else if value.id.indexOf("PUNCT") >= 0
                    punctuationErrs++
                else if value.category.toUpperCase().indexOf("GRAM") >= 0
                    grammarErrs++
                else
                    otherErrs++

        nCorrected = nErrors
        for i, value of doc.sentenceNLPData()
            if _.isObject(value) # Error has hint object attatched, other states are strings
                nCorrected -= 1

        # Add a summary if grammar check has started
        if nTotal > 0
            nIncorrect = image.nIncorrect
            for corrId, corr of image.corrections
                nIncorrect += corr.text.length
            accuracy   = Math.round(Math.sqrt((nTotal - nIncorrect) / nTotal) * 100)
            res = translations.essay_info_summary {nWords, language}

            # Add accuracy unless it is very low and count of corrected errors
            if accuracy > 50  then res += ' ' + translations.essay_accuracy_summary {accuracy}
            if nCorrected > 0 then res += ' ' + translations.essay_nerrors_corrected(nErrs: nCorrected)

            # Make detailed error summary (CSS controls display of this)
            lines = []
            displayErrors = (errors) -> _.uniq(_.sortBy(errors, _.identity), true).join ' '
            if spellingErrs.length > 0 # Add a line for spelling errors
                lines.push translations.essay_spelling_errors(err: displayErrors(spellingErrs))
            if accentErrs.length > 0 # Add a line for accent errors
                lines.push translations.essay_spelling_errors(err: displayErrors(accentErrs))
            if capitalizationErrs.length > 0 # Add a line for case errors
                lines.push translations.essay_capitalization_errors(err: displayErrors(capitalizationErrs))
            lastline = [] # Add a line to contain counts of other kinds of errors
            if grammarErrs > 0
                lastline.push translations.essay_grammar_errors(nErrs: grammarErrs)
            if punctuationErrs > 0
                lastline.push translations.essay_punctuation_errors(nErrs: punctuationErrs)
            if otherErrs > 0 and (lastline.length > 0 or lines.length > 0) # Don't add 'other errors' as sole category
                lastline.push translations.essay_other_errors(nErrs: otherErrs)
            if lastline.length > 0 then lines.push(lastline.join ', ')
            details = lines.join('\n')
            {
                accuracy: res
                details: details
            }
        else
            accuracy: ""
            details: ''

    banner: (role, getLanguageHint) ->
        doc = this
        image = this.image
        if role == ''
            ''
        else if role == 'mentor'
            # Mentor
            corrections = doc.correctionNLPData()
            result = ''
            for key, value of corrections
                if value? and _.isString(value.id)
                    hint   = getLanguageHint(value)
                    result = translations.correction_must_make_correct_sentence + hint
            # Default to no message
            result
        else
            # Author
            if !image.lang && _.keys(image.nlp).length == 0
                # If grammarchecking hasn't started yet..
                if image.text.length < 5
                    # Prompt to start writing
                    translations.essay_start_writing
                else if image.text.split(/\s/).length < 10
                    # Then carry on writing!
                    translations.essay_write_more
                else
                    # Prompt to finish the sentence
                    translations.essay_finish_sentence
            else
                # Language detected and grammar check started
                # No prompt
                ''
