window.setupWebsocketErrorProcessor = (wsrouter) ->

    # Logging route is for sending console logs
    logRoute = wsrouter.addRoute 'log', ((msg) ->)

    # Ignore various error messages that are unavoidable but routine & harmless
    shouldIgnore = (args) ->
        has = (str) -> args[1].toString().indexOf(str) >= 0
        # Chrome fires blur events even after node is removed from DOM, causing harmless exceptions
        isBlurUpdate =
            has("Perhaps it was moved in a 'blur'") or
                has("Object doesn't support property or method 'remove'")
        isMedia404 =
            has("AJAX request failed") and has("media.schoolshape.com") and (has("GET") or has("HEAD"))
        isSCEError =
            has("Attempting to use an unsafe value in a safe context")
        args[0] == 'error' and (isBlurUpdate or isMedia404 or isSCEError)

    # TODO rate limit
    lastSentMessage = 0
    nMessagesSinceReset = 0
    wsConsoleLog = (args) ->
        if shouldIgnore(args) then return

        # Rate limit messages
        now = Date.now()
        period = 10 # seconds
        maxInPeriod = 30
        if now - lastSentMessage > period * 1000
            nMessagesSinceReset = 0
        else if nMessagesSinceReset == maxInPeriod
            args[0] = "warn"
            args[1] = ["Logging rate limited for #{period} seconds"]
        else if nMessagesSinceReset > maxInPeriod
            # Ignore more messages to avoid overloading the server
            return

        lastSentMessage = Date.now()
        nMessagesSinceReset += 1

        # Limit messages to 1k per argument to avoid bandwidth overload
        maxL = 1024
        payload = args[1]
        for p, i in payload
            if typeof payload[i] == 'string' && payload[i].length > maxL
                payload[i] = payload[i].substring(0, maxL) + '... (truncated)'
        args[1] = payload

        msg = JSON.stringify(args)
        logRoute.send msg

    wsrouter.onConnect ->
        # Use console setF exactly once
        if _.isFunction(console.setF)
            # First drain the message queue, since the connection is open
            # we expect all the messages to be delivered ...
            console.setF wsConsoleLog
            # ... then prevent sequel.js from using setF
            console.setF = undefined
